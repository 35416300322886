import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ValidationListColumn, ValidationList } from '@app/common/models';
import { Store, select } from '@ngrx/store';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { Actions } from '@ngrx/effects';
import { environment } from '@env/environment';
import { ColumnTypes, NumberRangeValue, NumberValue, TextValue, DateRangeValue, BoolValue, ListValue } from '@app/common/models/columnBase.model';
import * as lodash from 'lodash';

@Component({
    selector: 'app-editvalidationlist-view',
    templateUrl: 'editValidationList.dialog.component.html',
    styleUrls:  ['./dialogs.component.scss'],
})

export class EditValidationListDialogComponent implements OnInit {

    validationListToView: ValidationList;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig
    ) {}

    ngOnInit() {

        this.validationListToView = this.config.data.validationListToView;

    }

    close() {
      this.validationListToView.changedAt = new Date();
      this.ref.close(this.validationListToView);
    }

    cancel() {
        this.ref.close();
    }

}
