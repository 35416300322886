import { selectListEditorState } from './state';
import { createSelector } from '@ngrx/store';


export const selectUploadData = createSelector(selectListEditorState, u => u.uploadStaging);
export const selectRowList = createSelector(selectListEditorState, s => s.rowList);
export const selectListName = createSelector(selectListEditorState, n => n.uploadName);

export const selectRowContentList = createSelector(selectListEditorState, r => r.rowContentList.filter(x => x.rowtype == 'content'));
export const selectRowHeaderList = createSelector(selectListEditorState, r => r.rowContentList.filter(x => x.rowtype == 'header'));
export const selectSingleRowContent = (id: number) => createSelector(selectListEditorState, r => {
    const rows = r.rowContentList.filter(x => x.id == id)
    if (!rows || rows.length < 1) return null;
    return rows[0];
});


export const selectValidationProfiles = createSelector(selectListEditorState, s => s.validationProfiles);
export const selectValidationsbyUsedColumnId = createSelector(selectListEditorState, s => s.validationsbyUsedColumnId);


export const selectErrorResult = createSelector(selectListEditorState, s => s.errorResult);
