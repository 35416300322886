import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { withLatestFrom, switchMap, map, tap } from 'rxjs/operators';
import { ValidationEditService } from '@app/common/services/validation-edit.service';
import { InitialValidationEditorState, selectValidationEditorState, ValidationEditorState } from './state';
import { GetAllAvailableColumnsAction, ValidationEditorActionType, GetAllValidationColumnsAction,
    GetAllAvailableColumnsDoneAction,
    GetAllValidationColumnsDoneAction,
    GetAllValidationListsAction,
    GetAllValidationListsDoneAction,
    AddNewValidationListAction,
    AddNewValidationListDoneAction,
    SaveValidationListAction,
    SaveValidationListDoneAction,
    AddAvailableColumnAction,
    AddAvailableColumnDoneAction,
    SaveAvailableColumnDoneAction,
    SaveAvailableColumnAction,
    SaveValidationColumnAction,
    SaveValidationColumnDoneAction,
    AddValidationColumnDoneAction,
    AddValidationColumnAction,
    CreateValidationColFromAvailableAction,
    CreateValidationColFromAvailableDoneAction,
    DeleteValidationListColumnDoneAction,
    DeleteValidationListColumnAction,
    DeleteAvailableColumnAction,
    DeleteAvailableColumnDoneAction,
    DeleteValidationListDoneAction,
    SaveValidationColumnsSortOrderAction,
    SaveValidationColumnOrderDoneAction,
    DeleteValidationListAction,
    GetStaticListValuesActionDone,
    GetStaticListValuesAction,
    UpdateStaticListValuesAction,
    UpdateStaticListValuesActionDone
} from './actions';
import { Observable, of } from 'rxjs';

@Injectable()
export class ValidationEditorEffects {
    state$: Observable<ValidationEditorState> = of (InitialValidationEditorState);



    constructor(
        private store: Store<any>,
        private actions: Actions,
        private service: ValidationEditService
    ) {

      this.state$ = this.store.pipe(select(selectValidationEditorState));

    }


    getAllAvailableColumns$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetAllAvailableColumnsAction>(ValidationEditorActionType.GetAllAvailableColumns),
        tap(console.log.bind(window)),
        switchMap(() => this.service.getAvailableColumns()),
        map(response => new GetAllAvailableColumnsDoneAction({ items: response }))
    )})


    getAllValidationListColumns$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetAllValidationColumnsAction>(ValidationEditorActionType.GetAllValidationColumns),
        tap(console.log.bind(window)),
        switchMap(() => this.service.getAllValidationColumns()),
        map(response => new GetAllValidationColumnsDoneAction({ items: response }))
    )})


    getAllValidationLists$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetAllValidationListsAction>(ValidationEditorActionType.GetAllValidationLists),
        tap(console.log.bind(window)),
        switchMap(() => this.service.getValidationLists()),
        map(response => new GetAllValidationListsDoneAction({ items: response }))
    )})


    addNewValidationList$ = createEffect(() => { return  this.actions.pipe(
        ofType<AddNewValidationListAction>(ValidationEditorActionType.AddNewValidationList),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.saveValidationList(action.payload.newValidationList)),
        map(response => new AddNewValidationListDoneAction({ newValidationList: response }))
    )})


    saveValidationList$ = createEffect(() => { return  this.actions.pipe(
        ofType<SaveValidationListAction>(ValidationEditorActionType.SaveValidationList),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.saveValidationList(action.payload.validationList)),
        map(response => new SaveValidationListDoneAction({ validationList: response }))
    )})


    addAvailableColumn$ = createEffect(() => { return  this.actions.pipe(
        ofType<AddAvailableColumnAction>(ValidationEditorActionType.AddAvailableColumn),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.saveAvailableColumn(action.payload.availableColumn)),
        map(response => new AddAvailableColumnDoneAction({ availableColumn: response }))
    )})


    saveAvailableColumn$ = createEffect(() => { return  this.actions.pipe(
        ofType<SaveAvailableColumnAction>(ValidationEditorActionType.SaveAvailableColumn),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.saveAvailableColumn(action.payload.column)),
        map(response => new SaveAvailableColumnDoneAction({ availableColumn: response }))
    )})



    getStaticListValues$ = createEffect(() => { return  this.actions.pipe(
      ofType<GetStaticListValuesAction>(ValidationEditorActionType.GetStaticListValuesAction),
      withLatestFrom(this.state$),
      switchMap(([action, state]) => this.service.getStaticListValues(action.payload.columnlistid, action.payload.listtabletype).pipe(
        map(response => new GetStaticListValuesActionDone({ usedcolumnlistid: action.payload.columnlistid, items: response }))
      )
    )
  )})

  updateStaticListValues$ = createEffect(() => { return  this.actions.pipe(
    ofType<UpdateStaticListValuesAction>(ValidationEditorActionType.UpdateStaticListValuesAction),
    withLatestFrom(this.state$),
    switchMap(([action, state]) => this.service.updateStaticListValues(action.payload.columnlistid, action.payload.items, action.payload.listtabletype).pipe(
      map(response => new UpdateStaticListValuesActionDone({ usedcolumnlistid: action.payload.columnlistid, items: response }))
    )
  )
)})


    addValidationColumn$ = createEffect(() => { return  this.actions.pipe(
        ofType<AddValidationColumnAction>(ValidationEditorActionType.AddValidationColumn),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.addValidationColumn(action.payload.validationColumn)),
        map(response => new AddValidationColumnDoneAction({ validationColumn: response }))
    )})


    saveValidationColumn$ = createEffect(() => { return  this.actions.pipe(
        ofType<SaveValidationColumnAction>(ValidationEditorActionType.SaveValidationColumn),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.saveValidationColumn(action.payload.column)),
        map(response => new SaveValidationColumnDoneAction({ validationColumn: response }))
    )})


    createValidationColumnFromAvailable$ = createEffect(() => { return  this.actions.pipe(
        ofType<CreateValidationColFromAvailableAction>(ValidationEditorActionType.CreateValidationColFromAvailable),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.createValidationColFromAvailable(action.payload.availableColumnId, action.payload.validationListId)),
        map(response => new CreateValidationColFromAvailableDoneAction({ validationColumn: response }))
    )
  })


    deleteValidationListColumn$ = createEffect(() => { return  this.actions.pipe(
        ofType<DeleteValidationListColumnAction>(ValidationEditorActionType.DeleteValidationListColumn),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.deleteValidationColumn(action.payload.colId)),
        map(response => new DeleteValidationListColumnDoneAction({ columnId: response }))
    )})


    deleteAvailableListColumn$ = createEffect(() => { return  this.actions.pipe(
        ofType<DeleteAvailableColumnAction>(ValidationEditorActionType.DeleteAvalaibleColumn),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.deleteAvailableColumn(action.payload.colId)),
        map(response => new DeleteAvailableColumnDoneAction({ colId: response }))
    )})


    deleteValidationList$ = createEffect(() => { return  this.actions.pipe(
        ofType<DeleteValidationListAction>(ValidationEditorActionType.DeleteValidationList),
        tap(console.log.bind(window)),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.service.deleteValidationList(action.payload.listId)),
        map(response => new DeleteValidationListDoneAction({ listId: response }))
    )})


    saveValidationColumnSortOrder$ = createEffect(() => { return  this.actions.pipe(
        ofType<SaveValidationColumnsSortOrderAction>(ValidationEditorActionType.SaveValidationColumnsSortOrder),
        tap(console.log.bind(window)),
        switchMap((action) => this.service.saveValidationColumnOrder(action.payload.columnOrder)),
        map(response => new SaveValidationColumnOrderDoneAction({ columnOrder: response.data }))
    )})

}
