import { HttpErrorResponse } from "@angular/common/http";

import { ResponseArgs } from "@app/api/filialmatrix/models";
import { GeneralResultEnum } from ".";

export class SimpleResult {

  constructor(generalResult: GeneralResultEnum, userMessage: string, serverMesssage: string, stack: string) {
    this.generalResult = generalResult;
    this.userMessage = userMessage;
    this.serverMessage = serverMesssage;
    this.stackTrace = stack;
  }

  generalResult: GeneralResultEnum;
  userMessage: string;
  serverMessage?: string;
  stackTrace?: string;

  /**Invalidproperties aus ResponesArgs
   * für Validierungshinweise
  */
  invalidProperties?: { [key: string]: string };

  /**Status code Text bei unbekannten HttpStatus codes zurückgeben */
  static getHttpCodeStateText(state: number): string {
    var idx = Object.values(SimpleResult.httpstatusCodes).findIndex(g => g == state);
    if (idx < 0) return "Unbekannter Fehler";
    return "Serverfehler " + state + " -> " + Object.keys(SimpleResult.httpstatusCodes)[idx];
  }

  /**Invalid Properties genauer auflösen */
  static getInvalidPropertiesText(invalidProperties: any, message: any): string {
    if (invalidProperties == undefined) return message;
    let str = "";
    Object.keys(invalidProperties).forEach(key => {
      str += key + " -> " + invalidProperties[key] + " \n";
    });
    let result = str && str.length > 0 ? str : message;
    return result;
  }

  /**HttpErroResponse  übersetzen*/
  static getFromHttpError(httpErr: HttpErrorResponse): SimpleResult {
    console.debug(httpErr);
    var err = <SimpleResult>{};
    if (httpErr.error == true && httpErr.error.hasOwnProperty("message") && httpErr.error.message.length > 0) {
      var respnse = httpErr.error as ResponseArgs;
      err = <SimpleResult>{
        invalidProperties: respnse.invalidProperties,
        generalResult: GeneralResultEnum.HttpError, userMessage: respnse.message, serverMessage:
          Object.keys(respnse.invalidProperties).length > 0 ? respnse.invalidProperties[Object.keys(respnse.invalidProperties)[0]] :
            respnse.message.toString()
      };
      // Kein direkter Fehler aber Invalid Properties
    } else if (httpErr.error != undefined && httpErr.error.hasOwnProperty("invalidProperties") && Object.keys(httpErr.error.invalidProperties).length > 0) {
      var respnse = httpErr.error as ResponseArgs;
      err = <SimpleResult>{
        invalidProperties: respnse.invalidProperties,
        generalResult: GeneralResultEnum.HttpError, userMessage: "Es trat ein Serverfehler auf", serverMessage:
          Object.keys(respnse.invalidProperties).length > 0 ? respnse.invalidProperties[Object.keys(respnse.invalidProperties)[0]] :
            respnse.message.toString()
      };
    } else if (httpErr.error != undefined && httpErr.error.hasOwnProperty("errors") && Object.keys(httpErr.error.errors).length > 0) {
      // hier ist errors als invalidproperties
      err = <SimpleResult>{
        invalidProperties: httpErr.error.errors,
        generalResult: GeneralResultEnum.HttpError, userMessage: SimpleResult.getInvalidPropertiesText(httpErr.error.errors, httpErr.error.title || "Es trat ein Serverfehler auf"), serverMessage:
          Object.keys(httpErr.error.errors).length > 0 ? httpErr.error.errors[Object.keys(httpErr.error.errors)[0]] :
            httpErr.message.toString()
      };
    } else if (httpErr.status == 0) {
      // Netzwerk Fehler
      err = <SimpleResult>{ generalResult: GeneralResultEnum.HttpError, userMessage: "Es trat ein Netzwerkfehler auf", serverMessage: "Der Service ist nicht erreichbar" };
    } else {
      switch (httpErr.status) {
        case 400:
          err = <SimpleResult>{ generalResult: GeneralResultEnum.HttpError, userMessage: "Fehlerhafter Serviceaufruf", serverMessage: httpErr.statusText.toString() };
          break;
        case 401:
          err = <SimpleResult>{ generalResult: GeneralResultEnum.AuthError, userMessage: "Keine gültige Authentifizierung", serverMessage: httpErr.statusText.toString() };
          break;
        case 404:
          err = <SimpleResult>{ generalResult: GeneralResultEnum.HttpError, userMessage: "Der Service ist nicht erreichbar", serverMessage: httpErr.statusText.toString() };
          break;
        case 500:
          err = <SimpleResult>{ generalResult: GeneralResultEnum.HttpError, userMessage: "Interner Fehler im Service", serverMessage: httpErr.statusText.toString() };
          break;
        default:
          err = <SimpleResult>{ generalResult: GeneralResultEnum.HttpError, userMessage: "Fehlerhafter Serviceaufruf", serverMessage: SimpleResult.getHttpCodeStateText(httpErr.status) };
      }
    }
    return err;
  }

  /**Evaluiert alle möglichen Fehler
   * und gibt  simpleReuslt zurück
   */
  static getSimpleResult(anyerror: any) {
    try {
      var simpleError = <SimpleResult>{};
      if (anyerror.hasOwnProperty("generalResult") && anyerror.hasOwnProperty("userMessage")) {
        simpleError = <SimpleResult>anyerror;
      } else if (anyerror instanceof AuthError) {
        var errauth = anyerror as AuthError;
        simpleError = <SimpleResult>{ generalResult: GeneralResultEnum.AuthError, userMessage: errauth.userMessage, serverMessage: errauth?.error.toString() };
      } else if (anyerror.error instanceof ErrorEvent) {
        var erEvent = anyerror.error as ErrorEvent;
        simpleError = <SimpleResult>{ generalResult: GeneralResultEnum.GeneralError, userMessage: "Es trat ein Fehler auf", serverMessage: erEvent.message, stackTrace: erEvent.type };
      } else if (anyerror.invalidProperties != undefined && anyerror.error == true) {
        var responseArgs = anyerror.error as ResponseArgs;
        simpleError = <SimpleResult>{ invalidProperties: responseArgs.invalidProperties, generalResult: GeneralResultEnum.GeneralError, userMessage: SimpleResult.getInvalidPropertiesText(responseArgs.invalidProperties, responseArgs.message), serverMessage: responseArgs.message, stackTrace: null };
      } else if (anyerror.hasOwnProperty("body") && anyerror.body.invalidProperties != undefined && anyerror.body.error == true) {
        var responseArgs = anyerror.body as ResponseArgs;
        simpleError = <SimpleResult>{ invalidProperties: responseArgs.invalidProperties, generalResult: GeneralResultEnum.GeneralError, userMessage: SimpleResult.getInvalidPropertiesText(responseArgs.invalidProperties, responseArgs.message), serverMessage: responseArgs.message, stackTrace: null };
      } else if (anyerror.hasOwnProperty("error") && anyerror.error != undefined && anyerror.error == true && anyerror.message != undefined) {
        var responseArgs = anyerror as ResponseArgs;
        simpleError = <SimpleResult>{ invalidProperties: responseArgs.invalidProperties, generalResult: GeneralResultEnum.GeneralError, userMessage: SimpleResult.getInvalidPropertiesText(responseArgs.invalidProperties, responseArgs.message), serverMessage: responseArgs.message, stackTrace: null };
      }  /*boolean Error kann false sein !!*/
      else if (anyerror.error != undefined && ((anyerror.error.message != undefined && anyerror.error.message.lengh > 0) || (anyerror.error.invalidProperties != undefined))) {
        var responseArgs = anyerror.error as ResponseArgs;
        simpleError = <SimpleResult>{ invalidProperties: responseArgs.invalidProperties, generalResult: GeneralResultEnum.GeneralError, userMessage: SimpleResult.getInvalidPropertiesText(responseArgs.invalidProperties, responseArgs.message), stackTrace: null };
      } else if (anyerror.error != undefined && anyerror.error.error == false && anyerror.error.message != undefined && anyerror.error.invalidProperties != undefined && Object.keys(anyerror.error.invalidProperties).length > 0) {
        var responseArgs = anyerror.error as ResponseArgs;
        simpleError = <SimpleResult>{ invalidProperties: responseArgs.invalidProperties, generalResult: GeneralResultEnum.GeneralError, userMessage: SimpleResult.getInvalidPropertiesText(responseArgs.invalidProperties, responseArgs.message), stackTrace: null };
      } else if (anyerror.error && anyerror.error != undefined && anyerror.error.error != undefined && anyerror.error.error.message != undefined) {
        simpleError = <SimpleResult>{ generalResult: GeneralResultEnum.GeneralError, userMessage: "Es trat ein Serverfehler auf", serverMessage: anyerror.error.error.message };
      } else if (anyerror instanceof HttpErrorResponse) {
        simpleError = this.getFromHttpError(anyerror as HttpErrorResponse);
      } else {
        simpleError = <SimpleResult>{ generalResult: GeneralResultEnum.GeneralError, userMessage: "Es trat ein Serverfehler auf", serverMessage: "Unbekannter Fehler" };
        console.debug(JSON.stringify(anyerror));
      }
    } catch (e) {
      return <SimpleResult>{ userMessage: "Fehler beim Errorhandling", generalResult: GeneralResultEnum.OnlyDebug, serverMessage: e.toString() };
    }
    return simpleError;
  }


  static httpstatusCodes = {
    "CONTINUE": 100,
    "SWITCHING_PROTOCOLS": 101,
    "PROCESSING": 102,
    "HEURISTIC_EXPIRATION": 113,
    "MISCELLANEOUS_WARNING": 199,
    "OK": 200,
    "CREATED": 201,
    "ACCEPTED": 202,
    "NON_AUTHORITATIVE_INFORMATION": 203,
    "NO_CONTENT": 204,
    "RESET_CONTENT": 205,
    "PARTIAL_CONTENT": 206,
    "MULTI_STATUS": 207,
    "ALREADY_REPORTED": 208,
    "TRANSFORMATION_APPLIED": 214,
    "IM_USED": 226,
    "MISCELLANEOUS_PERSISTENT_WARNING": 299,
    "MULTIPLE_CHOICES": 300,
    "MOVED_PERMANENTLY": 301,
    "FOUND": 302,
    "SEE_OTHER": 303,
    "NOT_MODIFIED": 304,
    "USE_PROXY": 305,
    "UNUSED": 306,
    "TEMPORARY_REDIRECT": 307,
    "PERMANENT_REDIRECT": 308,
    "TOO_MANY_REDIRECTS": 310,
    "BAD_REQUEST": 400,
    "UNAUTHORIZED": 401,
    "PAYMENT_REQUIRED": 402,
    "FORBIDDEN": 403,
    "NOT_FOUND": 404,
    "METHOD_NOT_ALLOWED": 405,
    "NOT_ACCEPTABLE": 406,
    "PROXY_AUTHENTICATION_REQUIRED": 407,
    "REQUEST_TIMEOUT": 408,
    "CONFLICT": 409,
    "GONE": 410,
    "LENGTH_REQUIRED": 411,
    "PRECONDITION_FAILED": 412,
    "PAYLOAD_TOO_LARGE": 413,
    "URI_TOO_LONG": 414,
    "UNSUPPORTED_MEDIA_TYPE": 415,
    "RANGE_NOT_SATISFIABLE": 416,
    "EXPECTATION_FAILED": 417,
    "IM_A_TEAPOT": 418,
    "MISDIRECT_REQUEST": 421,
    "UNPROCESSABLE_ENTITY": 422,
    "LOCKED": 423,
    "FAILED_DEPENDENCY": 424,
    "UNORDERED_COLLEcTION": 425,
    "UPGRADE_REQUIRED": 426,
    "PRECONDITION_REQUIRED": 428,
    "TOO_MANY_REQUEST": 429,
    "REQUEST_HEADER_FIELDS_TOO_LARGE": 431,
    "INTERNAL_SERVER_ERROR": 500,
    "NOT_IMPLEMENTED": 501,
    "BAD_GATEWAY": 502,
    "SERVICE_UNAVAILABLE": 503,
    "GATEWAY_TIMEOUT": 504,
    "HTTP_VERSION_NOT_SUPPORTED": 505,
    "VARIANT_ALSO_NEGOTIATES": 506,
    "INSUFFICIENT_STORAGE": 507,
    "LOOP_DETECTED": 508,
    "NOT_EXTENDED": 510,
    "NETWORK_AUTHENTICATION_REQUIRED": 511
  }

}


export class SimpleResultError extends SimpleResult {

  constructor(generalResult: GeneralResultEnum, userMessage: string, serverMesssage: string, stack: string, message: string) {
    super(generalResult, userMessage, serverMesssage, stack);
    this.message = message
  }


  message: string;

  static getSimpleResultError(anyerror: any, clientmessage: string) {
    try {
      var err = <SimpleResult>{};
      if (anyerror) {
        err = SimpleResult.getSimpleResult(anyerror);
      }
      var errmessage = <SimpleResultError>{ ...err };
      errmessage.message = clientmessage;
    } catch (e) {
      return <SimpleResultError>{ message: "Fehler beim Errorhandling", userMessage: "", generalResult: GeneralResultEnum.OnlyDebug, serverMessage: e.toString() };
    }
    return errmessage;
  }








}


/**SSO Fehler */
export class AuthError {
  constructor(message: string, err: any) {
    this.error = err;
    this.userMessage = message;
  }
  error: any;
  userMessage: string;
}

