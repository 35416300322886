  <div class="container-fluid">
    <div class="grid" style="padding-top:0.5rem">
          <mat-icon class="pageTitleIcon">list</mat-icon>
          <h2 class="pageTitleHeader">Aufgaben</h2>
        <i name="neueaufgabe" class="pi pi-plus pageAddButtonIcon" (click)="openTaskEditDialog('master', 'new')" pTooltip="Neue Aufgabe anlegen"></i>
    </div>
    <div class="contentSeperator"></div>
    <div class="row filterPanel grid">
        <div class="col-1">Filter:</div>
        <div class="col-1"><p-checkbox name="selectedFilters0" [value]="0" label="Alle" [(ngModel)]="selectedCategories" (onChange)="selectedAllCategories()"></p-checkbox></div>
        <div class="col-1"><p-checkbox name="selectedFilters1" [value]="100" label="Erstellt" [(ngModel)]="selectedCategories" (onChange)="selectSpecificCategory()"></p-checkbox></div>
        <div class="col-1"><p-checkbox name="selectedFilters2" [value]="200" label="Freigegeben" [(ngModel)]="selectedCategories" (onChange)="selectSpecificCategory()"></p-checkbox></div>
        <div class="col-1"><p-checkbox name="selectedFilters3" [value]="300" label="In Arbeit" [(ngModel)]="selectedCategories" (onChange)="selectSpecificCategory()"></p-checkbox></div>
        <div class="col-1"><p-checkbox name="selectedFilters4" [value]="500" label="Übermittelt" [(ngModel)]="selectedCategories" (onChange)="selectSpecificCategory()"></p-checkbox></div>
        <div class="col-1"><p-checkbox name="userFilter" [binary]="true" label="Nur Eigene" [(ngModel)]="showOwnTasksOnly" (onChange)="selectOwnTasks()"></p-checkbox></div>
        <div class="col-1"><p-checkbox name="archivFilter" [value]="700" label="Archiv" [(ngModel)]="selectedCategories" (onChange)="selectSpecificCategory()"></p-checkbox></div>
        <div class="col-2">
          <p-dropdown id="aufgabenart" [options]="aufgabenArten"  [(ngModel)]="selectedAufgabenart" showClear="true"
          (onClear)="selectAufgabenart()"
          optionLabel="value"  placeholder="Aufgabenart filtern" (ngModelChange)="selectAufgabenart()" >      </p-dropdown>
        </div>
      </div>
      <div class="row filterPanel grid">
        <div class="col-1">Sortierung:</div>
        <div class="col">
        <p-dropdown
         [options]="orderTaskOptions"
         optionLabel = "value"
         optionValue = "key"
         [(ngModel)]="selectedOrderTaskOption"
         appendTo = "body"
         (onChange)="onSelectedOrderTaskOptionChanged($event.value)">
        </p-dropdown>
      </div>
    </div>
    <div class="contentSeperator"></div>
    <p-treeTable #taskList [value]="tasks()" [columns]="userCols" [resizableColumns]="true"
        [rows]="this.pageSorting.rowsToLoad"
        [lazy]="true" (onLazyLoad)="loadTaskData($event)"
        [lazyLoadOnInit]="false"
        [totalRecords]="total$ | async"
        paginatorDropdownAppendTo="body"
        [first]="pageSorting.skipRows"
        [paginator]="true"
        [pageLinks]="3"
        [rowsPerPageOptions]="[20,50,100]"
        [showCurrentPageReport]="true"
        [currentPageReportTemplate]="'{first} bis {last} von {totalRecords} Aufgaben'"
        [rowHover]="true"
        >
        <ng-template pTemplate="header" let-columns>
            <tr >
            <th [ngClass]="'kb-header-cell-medium'" *ngIf="isManager"></th>
                <th [ngClass]="'kb-header-cell-small'"></th>
                <th [ngClass]="'kb-header-cell-small'"></th>
                <th [ngClass]="'kb-header-cell-small'"></th>
                <th *ngFor="let col of columns" [ttSortableColumn]="col.field"  ttResizableColumn  [ngClass]="'kb-header-cell-small'"  [ttSortableColumnDisabled]="!col.canOrder" >
                    {{col.header}}
                    <p-treeTableSortIcon *ngIf="col.canOrder"  [field]="col.field"></p-treeTableSortIcon>
            </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
           <tr  [ttRow]="rowNode"  [ngClass]="getUserTaskClass('kb-row', rowData)"  >
                <td *ngIf="rowData.userId == null" [ngClass]="getUserTaskClass('kb-cell-medium', rowData)">
                    <div>
                        <span class="badge badge-success badge-new" pTooltip="Erstellt">{{ getTaskCount(rowNode.node.children, "created") }}</span>
                        <span class="badge badge-success badge-none" pTooltip="Freigegeben">{{ getTaskCount(rowNode.node.children, "new") }}</span>
                        <span class="badge badge-success badge-ongoing" pTooltip="In Arbeit">{{ getTaskCount(rowNode.node.children, "ongoing") }}</span>
                        <!-- <span class="badge badge-success badge-done" pTooltip="Fertiggestellt">{{ getTaskCount(rowNode.node.children, "done") }}</span> -->
                        <span class="badge badge-success badge-transfered"  pTooltip="Übermittelt">{{ getTaskCount(rowNode.node.children, "transfered") }}</span>
                    </div>
                </td>
                <td *ngIf="rowData.userId == null" [ngClass]="getUserTaskClass('kb-cell-small', rowData)">
                    <i  id="addtaskforsupplier" class="pi pi-plus treeTableButton" (click)="createTasksForSuppliersShort( rowData, rowNode.node)"></i>
                </td>
                <td *ngIf="rowData.userId == null" #thistd [ngClass]="getUserTaskClass('kb-cell-small', rowData)">
                    <p-contextMenu [triggerEvent]="'click'" [id]="rowData.id"  #masterMenu [target]="masterActions" [model]="currentmainMenu()"
                         appendTo="body" (onHide)="hideMainMenue(rowData, rowNode.node, masterMenu)"
                        (onShow)="setSelectedTreeNode(rowData, rowNode.node, masterMenu )"></p-contextMenu>
                    <i #masterActions (click)="toggle($event, masterMenu)" class="pi pi-ellipsis-h treeTableButton"></i>
                </td>
                <td *ngIf="rowData.userId != null" [ngClass]="getUserTaskClass('kb-cell-small', rowData)"></td>
                <td *ngIf="rowData.userId == null" [ngClass]="getUserTaskClass('kb-cell-small', rowData)">
                  <ng-container *ngIf="rowNode.node.children?.length > 0" >
                    <p-treeTableToggler [rowNode]="rowNode" ></p-treeTableToggler>
                  </ng-container>
                </td>
                <td *ngIf="rowData.userId != null" [ngClass]="getUserTaskClass('kb-cell-small', rowData)"></td>
                <td *ngIf="rowData.userId != null" [ngClass]="getUserTaskClass('kb-cell-small', rowData)"></td>
                <td *ngIf="rowData.userId != null" [ngClass]="getUserTaskClass('kb-cell-small', rowData)">
                  <div *ngIf="hasChildMenus(rowNode.node.data)">
                    <p-contextMenu [triggerEvent]="'click'" #childmenu [id]="rowNode.node.data.id" [target]="childActions" [model]="currentchildMenu()"
                    (onHide)="hideChildMenu(rowData, rowNode.node, childmenu)"
                    appendTo="body"     (onShow)="setSelectedTreeItem(rowData, rowNode.node.data, childmenu)"></p-contextMenu>
                    <i  (click)="toggle($event, childmenu)" #childActions class="pi pi-ellipsis-h treeTableButton"></i>
                  </div>
                </td>
                <!-- TODO: Wird nicht verwendet -->
                <td [ngClass]="'kb-cell-small'" *ngIf="(!rowData.uploadState || rowData.uploadState < 20) && showUpload == true">
                  <button pButton  type="button" icon="pi pi-paperclip" styleClass="p-button-info" (click)='openUpload(rowData)'
                      pTooltip="Upload ansehen" tooltipPosition="top" showDelay="1000" hideDelay="1000"></button>
              </td>
              <td *ngIf="rowData.uploadState && rowData.uploadState >= 20 && showUpload == true" [ngClass]="'kb-cell-small'">
                  <div ><i class="pi pi-check"></i></div>
              </td>


                <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)">{{rowData.name}}</td>
                <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)">{{getAufgabenArtText(rowData.aufgabenArt)}}</td>
                <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)">{{rowData.sortimentName}}</td>
                <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)">{{rowData.taskValidRangeFrom | date}}</td>
                <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)">{{rowData.taskValidRangeTo | date}}</td>
                <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)" *ngIf="isManager">{{rowData.organization}}</td>
                <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)" *ngIf="isManager">{{ getPartner(rowData.lastName, rowData.firstName) | shorten: 20: '...': true}}</td>
                <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)" *ngIf="!isManager">{{rowData.creatorFriendlyName | shorten: 15: '...'}}</td>
                <td [ngClass]="getUserTaskClass('kb-cell-default', rowData)" *ngIf="!isManager">{{rowData.creatorMail | shorten: 15: '...': true}}</td>
            </tr>
        </ng-template>
    </p-treeTable>
</div>

<p-confirmDialog header="Bitte bestätigen:" icon="pi pi-exclamation-triangle" message="Keine Zeilen werden gelöscht." rejectLabel="Abbrechen"></p-confirmDialog>
<p-toast key="successMessage"></p-toast>

