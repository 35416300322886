import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of, EMPTY } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { AvailableColumn, ValidationList, ValidationListColumn, ResultObjectModel } from '../models';
import { SimpleResult } from '../models/simpleResult.model';
import { ColumnOrderModel } from '../models/columnOrder.model';
import { AuthService } from './auth.service';


@Injectable({ providedIn: 'root' })

export class ValidationEditService {


  constructor(private httpClient: HttpClient, private authService: AuthService, private notifier: NotificationService) {

  }

  getAvailableColumns(): Observable<AvailableColumn[]> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/GetAllAvailableColumns`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    let result: AvailableColumn[];
    const httpResult = this.httpClient.get<ResultObjectModel<AvailableColumn[]>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden der verfügbaren Spalten: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Laden der verfügbaren Spalten: ' + x.message);
        return of([]);
      })
    );
    return httpResult;
  }

  getAvailableColumnById(colId: number): Observable<AvailableColumn> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/GetAvailableColumnById`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { id: colId.toString() }
    };
    let result: AvailableColumn;
    const httpResult = this.httpClient.get<ResultObjectModel<AvailableColumn>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden einer verfügbaren Spalte: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Laden einer verfügbaren Spalte: ' + x.message);
        return EMPTY;
      })
    );
    return httpResult;
  }


  GetUsedValidationListById(usedColumnid: any):  Observable<ValidationListColumn> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/GetUsedValidationListById`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }) , params: { usedColumnid: usedColumnid.toString() }
    };
    let result: ValidationListColumn;
    const httpResult = this.httpClient.get<ResultObjectModel<ValidationListColumn>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden der Validierungsliste: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Laden der Validierungsliste: ' + x.message);
        return of(null);
      })
    );
    return httpResult;
  }


  getAllValidationColumns(): Observable<ValidationListColumn[]> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/GetAllValidationColumns`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    let result: ValidationListColumn[];
    const httpResult = this.httpClient.get<ResultObjectModel<ValidationListColumn[]>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden der Validierungslisten: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Laden der Validierungslisten: ' + x.message);
        return of([]);
      })
    );
    return httpResult;
  }

  getValidationLists(): Observable<ValidationList[]> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/GetAllValidationLists`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    let result: ValidationList[];
    const httpResult = this.httpClient.get<ResultObjectModel<ValidationList[]>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden der Validierungslisten: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Laden der Validierungslisten: ' + x.message);
        return of([]);
      })
    );
    return httpResult;
  }

  getAllValidationListsWithColumns(): Observable<ValidationList[]> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/GetAllValidationListsWithColumns`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    let result: ValidationList[];
    const httpResult = this.httpClient.get<ResultObjectModel<ValidationList[]>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden der Validierungslisten mit Spalten: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Laden der Validierungslisten mit Spalten: ' + x.message);
        return of([]);
      })
    );
    return httpResult;
  }

  getValidationColumnById(colId: number): Observable<ValidationListColumn> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/GetValidationColById`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { id: colId.toString() }
    };
    let result: ValidationListColumn;
    const httpResult = this.httpClient.get<ResultObjectModel<ValidationListColumn>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden einer verfügbaren Spalte: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Laden einer verfügbaren Spalte: ' + x.message);
        return EMPTY;
      })
    );
    return httpResult;
  }

  getValidationColumnsForListId(listId: number): Observable<ValidationListColumn[]> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/GetValidationColsForList`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { id: listId.toString() }
    };
    let result: ValidationListColumn[];
    const httpResult = this.httpClient.get<ResultObjectModel<ValidationListColumn[]>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden der Spalten für eine Liste: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Laden der Spalten für eine Liste: ' + x.message);
        return of([]);
      })
    );
    return httpResult;
  }

  saveValidationList(validationList: ValidationList): Observable<ValidationList> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/SaveValidationList`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    let result: ValidationList;
    const httpResult = this.httpClient.post<ResultObjectModel<ValidationList>>(url, validationList, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Speichern einer Validierungsliste: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Speichern einer Validierungsliste: ' + x.message);
        return EMPTY;
      })
    );
    return httpResult;
  }

  saveAvailableColumn(availableColumn: AvailableColumn): Observable<AvailableColumn> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/SaveAvailableColumn`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    let result: AvailableColumn;
    const httpResult = this.httpClient.post<ResultObjectModel<AvailableColumn>>(url, availableColumn, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Speichern einer generellen Spalte: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Speichern einer generellen Spalte: ' + x.message);
        return EMPTY;
      })
    );
    return httpResult;
  }

  saveValidationColumn(validationListColumn: ValidationListColumn): Observable<ValidationListColumn> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/SaveValidationColumn`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    let result: ValidationListColumn;
    const httpResult = this.httpClient.post<ResultObjectModel<ValidationListColumn>>(url, validationListColumn, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Speichern einer Listen-Spalte: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Speichern einer Listen-Spalte: ' + x.message);
        return EMPTY;
      })
    );
    return httpResult;
  }

  addValidationColumn(validationListColumn: ValidationListColumn): Observable<ValidationListColumn> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/CreateNewValidationColumn`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    let result: ValidationListColumn;
    const httpResult = this.httpClient.post<ResultObjectModel<ValidationListColumn>>(url, validationListColumn, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Speichern einer Listen-Spalte: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Speichern einer Listen-Spalte: ' + x.message);
        return EMPTY;
      })
    );
    return httpResult;
  }

  createValidationColFromAvailable(availableId: number, listId: number): Observable<ValidationListColumn> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/CreateValidationColFromAvailable`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { availableColumnId: availableId.toString(), validationListId: listId.toString() }
    };
    let result: ValidationListColumn;
    const httpResult = this.httpClient.get<ResultObjectModel<ValidationListColumn>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler bei der Übernahme einer Listen-Spalte: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler bei der Übernahme einer Listen-Spalte: ' + x.message);
        return EMPTY;
      })
    );
    return httpResult;
  }

  deleteValidationColumn(columnId: number): Observable<number> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/DeleteValidationListColumn`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { id: columnId.toString() }
    };
    let result: number;
    const httpResult = this.httpClient.get<ResultObjectModel<number>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler bei Löschen einer Listen-Spalte: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beibeim Löschen einer Listen-Spalte: ' + x.message);
        return EMPTY;
      })
    );
    return httpResult;
  }

  deleteAvailableColumn(columnId: number): Observable<number> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/DeleteAvailableColumn`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { id: columnId.toString() }
    };
    let result: number;
    const httpResult = this.httpClient.get<ResultObjectModel<number>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler bei Löschen einer Listen-Spalte: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beibeim Löschen einer Listen-Spalte: ' + x.message);
        return EMPTY;
      })
    );
    return httpResult;
  }

  deleteValidationList(listId: number): Observable<number> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/DeleteValidationList`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      params: { id: listId.toString() }
    };
    let result: number;
    const httpResult = this.httpClient.get<ResultObjectModel<number>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler bei Löschen einer Validierungsliste: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beibeim Löschen einer Validierungsliste: ' + x.message);
        return EMPTY;
      })
    );
    return httpResult;
  }

  saveValidationColumnOrder(columnOrder: ColumnOrderModel[]): Observable<ResultObjectModel<ColumnOrderModel[]>> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/ReorderValidationListColumns`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    let result: SimpleResult;
    const httpResult = this.httpClient.post<ResultObjectModel<ColumnOrderModel[]>>(url, columnOrder, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler bei Speichern der Sortierung: ' + x.userMessage);
        }
        return x;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Speichern der Sortierung: ' + x.message);
        return EMPTY;
      })
    );

    return httpResult;
  }

  getValidationProfiles(): Observable<ValidationList[]> {
    console.log('Getting Validation-Profiles...');

    const url = `${environment.connections.ValidationEditService}/ValidationProcess/GetActiveValidationLists`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    let result: ValidationList[];
    const httpResult = this.httpClient.get<ResultObjectModel<ValidationList[]>>(url, httpOptions).pipe(
      map(x => {
        if (x.generalResult != 0) {
          this.notifier.showError('ServiceError: Fehler beim Laden der aktiven Validierungslisten: ' + x.userMessage);
          return result;
        }
        result = x.data;
        return result;
      }),
      catchError(x => {
        this.notifier.showError('Fehler beim Laden der aktiven Validierungslisten: ' + x.message);
        return of([]);
      })
    );
    return httpResult;
  }

  GetArticleDataValidations(): Observable<ResultObjectModel<ValidationList[]>> {
    const url = `${environment.connections.ValidationEditService}/ValidationEdit/GetActiveLiefValidationLists`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    };
    const httpResult = this.httpClient.get<ResultObjectModel<ValidationList[]>>(url, httpOptions);
    return httpResult;
  }

}
