<div class="grid">
    <div class="col-6">
      <div class="grid">
        <div class="col-4"><label for="colName">Name der Spalte</label></div>
        <div class="col-8"><input #colName type="text" pInputText [(ngModel)]="validationColumnToView.colName" class="textinput" /></div>
      </div>
      <div class="grid">
        <div class="col-4"><label for="colDesc">Beschreibung</label></div>
        <div class="col-8"><textarea  id="colDesc" pInputTextarea [(ngModel)]="validationColumnToView.colDesc" class="w-full"></textarea></div>
      </div>
      <div class="grid">
        <div class="col-4"><label for="colType">Spaltentyp</label></div>
        <div class="col-8"><p-dropdown  appendTo="body" (onChange)="changeListenTyp($event)" [options]="colTypeOptions" [(ngModel)]="validationColumnToView.colType" styleClass="w-full"></p-dropdown></div>
      </div>
      <div class="grid">
        <div class="col-4"><label for="dbMapping">Db-Mapping</label></div>
        <div class="col-8">
          <p-dropdown [options]="settingValueList()"  [ngModel]="selecteddbMapping()" appendTo="body" styleClass="w-full"
          dataKey="settingValue" showClear="true" (onClear)="clearDbMapping()"
          optionLabel="settingValue"
          (onChange)="changeDbMapping($event)" ></p-dropdown>
        </div>
      </div>
      <div class="grid">
        <div class="col-4"><label for="bubMapping">BuB-Mapping</label></div>
        <div class="col-8"><input #bubMapping type="text" pInputText [(ngModel)]="validationColumnToView.bubMapping" class="w-full" /></div>
      </div>
      <div class="grid">
        <div class="col-4"><label for="isMandatory">Pflichtfeld</label></div>
        <div class="col-8"><p-checkbox [(ngModel)]="validationColumnToView.isMandatory" binary="true" class="w-full"></p-checkbox></div>
      </div>
      <div class="grid">
        <div class="col-4"></div>
        <div class="col-8"><p-message  styleClass="w-full" *ngIf="hint()" severity="info"  [text]="hint() ">  </p-message>
        </div>
        </div>
    </div>
    <div class="col-1">&nbsp;</div>
    <div class="col-5">
      <div class="grid">
        <div class="col-12">
            <h6 style="padding-left: 15px;">Vorgaben für die Validierung</h6>
        </div>
      </div>
        <div *ngIf="validationColumnToView.colType == colEnum.Text">
          <div class="grid">
            <div class="col-6"><label for="maxLength" style="padding-left: 15px;">Maximale Länge</label></div>
            <div class="col-6"><p-spinner #maxLength [(ngModel)]="textValue.maxlength" [step]="1" [formatInput]="true" thousandSeparator="." decimalSeparator="," [size]="8"></p-spinner></div>
          </div>
          <div class="grid">
            <div class="col-6"><label for="regEx" style="padding-left: 15px;">Erlaubte Werte</label></div>
            <div class="col-6"><input #regEx type="text" [(ngModel)]="textValue.validregex" pInputText style="padding-right: 15px;" /></div>
          </div>
        </div>
        <div *ngIf="validationColumnToView.colType == colEnum.Number">
          <div class="grid">
            <div class="col-6"><label for="numberMin" style="padding-left: 15px;">Stellen</label></div>
            <div class="col-6"><p-spinner  #numberMin [step]="1" [(ngModel)]="numberValue.maxdigits" min="0" [formatInput]="true" thousandSeparator="." decimalSeparator="," [size]="8"></p-spinner></div>
          </div>
          <div class="grid">
            <div class="col-6"><label for="numberMax"  style="padding-left: 15px;">Nachkommastellen</label></div>
            <div class="col-6"><p-spinner #numberMax [step]="1" [(ngModel)]="numberValue.maxdecimals" min="0"  max="9" [formatInput]="true" thousandSeparator="." decimalSeparator="," [size]="8"></p-spinner></div>
          </div>
          <div class="grid">
            <div class="col-6"><label for="isNumberOnly" style="padding-left: 15px;">Ganzzahlen</label></div>
            <div class="col-6"><p-checkbox binary="true" [(ngModel)]="numberValue.isnumberonly"></p-checkbox> </div>
          </div>
        </div>
          <div *ngIf="validationColumnToView.colType == colEnum.DynamicList">

           <div class="grid">
            <div class="col-12"> <label style="margin-left: 15px;">  {{dynmlistValue && dynmlistValue?.value == 1 ? ' Möbelauswahl' : ''}}</label></div>
            <!-- <div class="col-4"><label for="dynlist">Dynamische Liste</label></div>
            <div class="col-8"><p-dropdown optionLabel="label"  id="dynlist" [style]="{'width': '100%'}"  appendTo="body"
              [options]="dynListOptions" [(ngModel)]="dynmlistValue" ></p-dropdown></div> -->
          </div>
        </div>
        <div *ngIf="validationColumnToView.colType == colEnum.List">
          <div class="grid">
            <div class="col-4" style="font-size:larger">
                Liste
          </div>
          <div class="col-8"></div>
          </div>

          <div class="grid">
            <div class="col-12" style="margin-bottom: 5px;">
                <button pButton type="button" icon="pi pi-plus" (click)="addNewListValue()" style="float: right;"></button>
            </div>
          </div>
          <div class="grid">
            <div class="col-12">
                <p-table [value]="listValue.valuelist" [rows]="5" editMode="cell" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Werte</th>
                            <th style="width: 4em;">&nbsp;</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td pEditableColumn>
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="rowData.item" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ rowData.item }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td style="width: 4em;">
                                <button pButton type="button" icon="pi pi-trash" (click)="deleteListValue(rowData.item)"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
          </div>
        </div>
        <div [ngClass]=" datePickerOpen == true  ? 'dialogDateRangeDiv' : ''" *ngIf="validationColumnToView.colType == colEnum.DateRange">
          <div class="grid">
            <div class="col-5"><label for="dateRangeStart" style="margin-left: 15px;">Startdatum</label></div>
            <div class="col-7"><p-calendar (onShow)="datePickerOpen = true;" (onClose)="datePickerOpen = false;"   #dateRangeStart [(ngModel)]="dateRangeValue.daterangestart" [showIcon]="true" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"></p-calendar></div>
          </div>
          <div class="grid">
            <div class="col-5"><label for="dateRangeEnd" style="margin-left: 15px;">Enddatum</label></div>
            <div class="col-7"><p-calendar (onShow)="datePickerOpen = true;" (onClose)="datePickerOpen = false;"  #dateRangeEnd [(ngModel)]="dateRangeValue.daterangeend" [showIcon]="true" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"></p-calendar></div>
          </div>
        </div>
        <div *ngIf="validationColumnToView.colType == colEnum.NumberRange">
          <div class="grid">
            <div class="col-6"><label for="numberRangeMin" style="padding-left: 15px;">Minimum</label></div>
            <div class="col-6"><p-spinner  #numberRangeMin [step]="0.1" [(ngModel)]="numberRangeValue.numberrangestart" [formatInput]="true" thousandSeparator="." decimalSeparator="," [size]="8"></p-spinner></div>
          </div>
          <div class="grid">
            <div class="col-6"><label for="numberRangeMax"  style="padding-left: 15px;">Maximum</label></div>
            <div class="col-6"><p-spinner #numberMax [step]="0.1" [(ngModel)]="numberRangeValue.numberrangeend" [formatInput]="true" thousandSeparator="." decimalSeparator="," [size]="8"></p-spinner></div>
          </div>
        </div>
        <div *ngIf="validationColumnToView.colType == colEnum.CheckBox">
          <div class="grid">
            <div class="col-12"><label style="margin-left: 15px;">Keine weiteren Konfigurationsmöglichkeiten</label></div>
          </div>
        </div>
        <div *ngIf="validationColumnToView.colType == colEnum.Date">
          <div class="grid">
            <div class="col-12"><label style="margin-left: 15px;">Keine weiteren Konfigurationsmöglichkeiten</label></div>
          </div>
        </div>
        <div *ngIf="validationColumnToView.colType == colEnum.ExternalValidation">
          <div class="grid">
            <div class="col-12"><label style="margin-left: 15px;">Derzeit nicht implementiert</label></div>
          </div>
        </div>
    </div>
</div>

<div class="grid" style="margin-top: 20px; float: right; padding-right: 20px;">
    <button pButton type="button" icon="pi pi-times" label="Abbrechen" style="margin-left: 15px;" (click)="cancel()"></button>
    <button pButton type="button" icon="pi pi-check" label="Speichern" style="margin-left: 15px;" (click)="close()"></button>
</div>
