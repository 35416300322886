import { Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { AvailableColumn } from '@app/common/models';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ColumnTypes, NumberRangeValue, NumberValue, TextValue, DateRangeValue, BoolValue, ListValue, DateValue, DynListValue, DynListOption } from '@app/common/models/columnBase.model';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { ISetting, ISettingChildrenValue, ISettingValue, SettingValueEnum, SettingValueTypeEnum } from '@app/common/models/setting.models';
import { CommonCheckService } from '@app/common/services/common.validation.service';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { GetSettingsChildrenAction, GetSettingsStateAction, selectSettingChildrenValues, selectSettingValues } from '@app/common-data-module/store';
import { BranchselektorDialogComponent } from '@app/+settings/lipo-settings/controls/branchgroup-control/dialogs/branchselektor.dialog/branchselektor.dialog.component';


@Component({
  selector: 'app-editavailablecolumn-view',
  templateUrl: 'editAvailableColumn.dialog.component.html',
  styleUrls: ['./dialogs.component.scss'],
})

export class EditAvailableColumnDialogComponent implements OnInit {

  availableColumnToView: AvailableColumn;
  numberRangeValue: NumberRangeValue = { numberrangestart: 0, numberrangeend: 0 };
  dateRangeValue: DateRangeValue = { daterangestart: new Date, daterangeend: new Date };
  numberValue: NumberValue = { maxdigits: 0, maxdecimals: 0, isnumberonly: false };
  textValue: TextValue = { maxlength: 120, validregex: null };
  listValue: ListValue = { valuelist: [] };
  dynListOptions: DynListValue[] = [];
  dynmlistValue: DynListValue =  null;

  checkBoxValue: BoolValue = { value: null };
  dateValue: DateValue = { value: null };

  colTypeOptions: { label: string, value: number }[] = [];
  colEnum: any;

  hint: WritableSignal<string> = signal(null);

  settingValueList: WritableSignal<ISettingValue[]> = signal([]);
  selecteddbMapping: WritableSignal<ISettingValue> = signal(null);
  selectedChildren: WritableSignal<{ [key: string]: ISettingChildrenValue[] }> = signal({});

  constructor(
    private store: Store<any>,
    private validateService: CommonCheckService,
    @Inject(DynamicDialogRef) public ref: DynamicDialogRef,
    @Inject(DynamicDialogConfig) public config: DynamicDialogConfig
  ) { }

  ngOnInit() {


    const keys1 = Object.keys(DynListOption).filter(key => !isNaN(DynListOption[key]));
    keys1.forEach(x => this.dynListOptions.push({ label: x, value: DynListOption[x] }));
    this.dynmlistValue = {...this.dynListOptions.find(f => f.value == 1)};


    this.availableColumnToView = this.config.data.availableColumnToView;
    if (this.availableColumnToView.validationRule) {
      switch (this.availableColumnToView.colType) {
        case (ColumnTypes.Text):
          this.textValue = JSON.parse(this.availableColumnToView.validationRule);
          break;
        case (ColumnTypes.DateRange):
          this.dateRangeValue = JSON.parse(this.availableColumnToView.validationRule);
          break;
        case (ColumnTypes.NumberRange):
          this.numberRangeValue = JSON.parse(this.availableColumnToView.validationRule);
          break;
        case (ColumnTypes.List):
          this.listValue = JSON.parse(this.availableColumnToView.validationRule);
          break;
        case (ColumnTypes.Number):
          this.numberValue = JSON.parse(this.availableColumnToView.validationRule);
          break;
        case (ColumnTypes.CheckBox):
          this.checkBoxValue = JSON.parse(this.availableColumnToView.validationRule);
          break;
        case (ColumnTypes.Date):
          this.dateValue = JSON.parse(this.availableColumnToView.validationRule);
          break;
          case (ColumnTypes.DynamicList):
          let result  = JSON.parse(this.availableColumnToView.validationRule);
             this.dynmlistValue = {...this.dynListOptions.find(f => f.value == result.value)};
          break;

      }
    }

    const keys = Object.keys(ColumnTypes).filter(key => !isNaN(ColumnTypes[key]));
    keys.forEach(x => this.colTypeOptions.push({ label: x, value: ColumnTypes[x] }));
    this.colEnum = ColumnTypes;




    this.store.dispatch(new GetSettingsStateAction({ settingid:  SettingValueEnum.DBMapping, onlyActive: true }));

    this.store.pipe(select(selectSettingValues)).pipe(filter(f => f && f.length > 0)).subscribe(d => {
      if (this.availableColumnToView.dbMapping && !d.find(f => f.settingValue == this.availableColumnToView.dbMapping)) {
        d = this.validateService.getClone(d);
        var current = <ISettingValue>{ id: 0, setting_Id: SettingValueEnum.DBMapping, settingValue: this.availableColumnToView.dbMapping, isActive: 1 };
        d.unshift(current);
        this.hint.set('Die Datenbankzuordnung wurde nicht gefunden. ');
      }

      this.settingValueList.set(d);
      this.selecteddbMapping.set(d.find(f => f.settingValue == this.availableColumnToView.dbMapping));
      if (this.selecteddbMapping()) {
        this.changeDbMapping({ value: this.selecteddbMapping(), originalEvent: null }, true);
      }

    }
    )


  }

  clearDbMapping()
  {
    this.availableColumnToView = this.validateService.getClone(this.availableColumnToView);
    this.availableColumnToView.colType = ColumnTypes.Text;
    this.availableColumnToView.dbMapping  = null;
    this.availableColumnToView.bubMapping = null;
  }



  changeDbMapping(e: DropdownChangeEvent, init: boolean = false) {

    this.hint.set(null);
    this.availableColumnToView = this.validateService.getClone(this.availableColumnToView);
    this.availableColumnToView.dbMapping = e.value.settingValue;
    this.availableColumnToView.bubMapping = null;
    if (this.selectedChildren()[e.value.id] == undefined || this.selectedChildren()[e.value.id].length == 0) {
      this.store.dispatch(new GetSettingsChildrenAction({ settingvalueId: e.value.id }));
    }
    var hint = null;
    this.store.pipe(select(selectSettingChildrenValues)).subscribe(data => {
      var elements = data[e.value.id];
      if (elements instanceof Array) {
        this.availableColumnToView = this.validateService.getClone(this.availableColumnToView);
        var coltype = elements.find(x => x.name == 'columntype');
        if (coltype && coltype.settingValue) {
          this.availableColumnToView.colType = Number(coltype.settingValue) as ColumnTypes;
        }
        var bub = elements.find(x => x.name == 'bubmapping');
        if (bub && bub.settingValue) {
          if (init && this.availableColumnToView.bubMapping && this.availableColumnToView.bubMapping != bub.settingValue) {
            hint += 'BubMapping wurde geändert';
          }
          if (this.availableColumnToView.dbMapping == e.value.settingValue )
          {
            this.availableColumnToView.bubMapping = bub.settingValue;
          }
        }
        var desc = elements.find(x => x.name == 'description');
        if (desc && desc.settingValue && !init) {
          if (!this.availableColumnToView.colDesc || this.availableColumnToView.colDesc.indexOf(desc.settingValue) == -1) {
            this.availableColumnToView.colDesc = desc.settingValue;
          }
        }
      }
    })

    if (init == true) {
      this.hint.set(hint);
    }
  }

  close() {
    switch (this.availableColumnToView.colType) {
      case (ColumnTypes.Text):
        if (this.textValue) this.availableColumnToView.validationRule = JSON.stringify(this.textValue);
        break;
      case (ColumnTypes.DateRange):
        if (this.dateRangeValue) this.availableColumnToView.validationRule = JSON.stringify(this.dateRangeValue);
        break;
      case (ColumnTypes.NumberRange):
        if (this.numberRangeValue) this.availableColumnToView.validationRule = JSON.stringify(this.numberRangeValue);
        break;
      case (ColumnTypes.List):
        if (this.listValue) this.availableColumnToView.validationRule = JSON.stringify(this.listValue);
        break;
      case (ColumnTypes.Number):
        if (this.numberValue) this.availableColumnToView.validationRule = JSON.stringify(this.numberValue);
        break;
      case (ColumnTypes.CheckBox):
        if (this.checkBoxValue) this.availableColumnToView.validationRule = JSON.stringify(this.checkBoxValue);
        break;
      case (ColumnTypes.Date):
        if (this.dateValue) this.availableColumnToView.validationRule = JSON.stringify(this.dateValue);
        break;
        case (ColumnTypes.DynamicList):
          if (this.dynmlistValue) this.availableColumnToView.validationRule = JSON.stringify(this.dynmlistValue);
          break;
    }

    this.ref.close(this.availableColumnToView);
  }

  cancel() {
    this.ref.close();
  }

  deleteListValue(itemValue: string) {
    const idx = this.listValue.valuelist.findIndex(x => x.item == itemValue);
    this.listValue.valuelist.splice(idx, 1);
  }

  addNewListValue() {
    this.listValue.valuelist.push({ item: "Neuer Eintrag" });
  }
}
