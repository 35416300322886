import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectTaskEditorState } from './state';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
    GetValidationsAction,
    TaskEditorActionType,
    GetValidationsActionDone,
    GetBreadcrumbAction,
    GetBreadcrumbActionDone,

    ResetStoreAction,
    GetUsersAction,
    GetUsersDoneAction,
    GetCurrentUserAction,
    GetCurrentUserDoneAction,
    CreateMasterTaskAction,
    CreateMasterTaskDoneAction,
    SkipTaskAction,
    SkipTaskDoneAction,


} from './actions';
import { withLatestFrom, switchMap, map, tap, filter, mergeMap } from 'rxjs/operators';
import { ValidationService } from '@app/common/services/validation.service';
import { TaskService } from '@app/common/services/task.service';
import { UserService } from '@app/common/services/user.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class TaskEditorEffects {
    state$ = this.store.pipe(select(selectTaskEditorState));

    constructor(
        private store: Store<any>,
        private actions: Actions,
        private service: ValidationService,
        private taskService: TaskService,
        private userService: UserService,
        private messageService: MessageService
    ) { }


    getValidationProfiles$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetValidationsAction>(TaskEditorActionType.GetValidations),
        tap(console.log.bind(window)),
        switchMap(() => this.service.getValidationProfiles()),
        map(response => new GetValidationsActionDone({ items: response }))
    )})


    getBreadcrumb$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetBreadcrumbAction>(TaskEditorActionType.GetBreadcrumb),
        withLatestFrom(this.state$),
        switchMap(([action, state]) => this.taskService.getBreadcrumb(action.options.id)),
        map(response => new GetBreadcrumbActionDone({ item: response }))
        )})







    getUsers$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetUsersAction>(TaskEditorActionType.GetUsers),
        switchMap((action) =>
            this.taskService.getSelectableUsers().pipe(
                map(response => new GetUsersDoneAction({ users: response }))
            )
        )
        )})


    getCurrentUser$ = createEffect(() => { return  this.actions.pipe(
        ofType<GetCurrentUserAction>(TaskEditorActionType.GetCurrentUser),
        switchMap((action) =>
            this.userService.get().pipe(
                map(response => new GetCurrentUserDoneAction({ user: response }))
            )
        )
        )})


    createTask$ = createEffect(() => { return  this.actions.pipe(
        ofType<CreateMasterTaskAction>(TaskEditorActionType.CreateMasterTask),
        switchMap((action) =>
            this.taskService.createMasterTask(action.payload.item).pipe(
                    map(response => new CreateMasterTaskDoneAction({ item: response }) )
                )
        )
        )})


    skipTask$ = createEffect(() => { return  this.actions.pipe(
        ofType<SkipTaskAction>(TaskEditorActionType.SkipTask),
        switchMap((action) => this.taskService.skipTask(action.options.taskId)),
        map(response => new SkipTaskDoneAction({ item: response }))
        )})


    skipTaskDone$ = createEffect(() => { return  this.actions.pipe(
        ofType<SkipTaskDoneAction>(TaskEditorActionType.SkipTaskDone),
        tap(action => {
            console.log('<SKIP> Result: ', action.payload.item.generalResult);
            if (action.payload.item.generalResult === 0 || action.payload.item.generalResult === null) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Erfolgreich übersprungen',
                    detail: 'Die Aufgabe wurde im System als ÜBERSPRUNGEN markiert.'
                });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Fehler beim überspringen', detail: action.payload.item.userMessage });
            }})
            )})
}
