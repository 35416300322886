import { Injectable } from "@angular/core";
import { ExtendedOrderByDTO, ListeDto, NovitaetDto, QueryTokenDTO, UserDto } from "@app/api/filialmatrix/models";
import { select, Store } from "@ngrx/store";
import { filter, map, Observable, take } from "rxjs";

import {
  CommonDataActionType, CommonDataErrorAction, GetAktuellenEinkaufAction, GetEinkaeufeAction, GetMarketingEreignisseAction, GetNovitaetBubDataByEansAction, GetNovitaetenKategorienAction, GetKlammernAction,
  GetNovitaetenListenAction,
  GetNovitaetenLookupsIdNameAction,
  GetNovitaetenLookupsIntAction,
  GetNovitaetenLookupsStringAction,
  GetNovitaetenSoMaContactsAction, GetSortimenteAction, GetNovitaetenWarengruppenAction, GetRegionenAction, GetSaisonsAction, selectAktuellerEinkauf, selectallEinkaeufe, selectallKategorien, selectallKlammern, selectallMarketingereignisse,
  selectallRegionen, selectallSaisons, selectallSortimente, selectallWarengruppen, selectedLipoContactsSOMA,
  selectListenTypenbyKey,
  selectNovitaetBubDataList, selectNovitaetenLookupsbyKey, GetVerlagebySearchAction, GetVerlagebySearchActionDone, selectSearchedVerlage, GetCompareListAction, selectCompareTitles, GetCompareListActionDone, GetKategorienderVorabplatzierungen, selectKategorienausVorabplatzierungen, GetKategorienderVorabplatzierungenDone, GetZukuenftigeEinkaufAction, selectzukuenftigeEinkaeufe, selectzukuenftigeEinkaeufeisLoading, ClearCommonaDataCacheAction,
  selectallBranchGroups,
  selectallBranches,
  GetBranchesAction,
  GetBranchGroupsAction,
  selectNovitaetBubDataLoading
} from "../store";
import { EinkaufService, EreignisService, KlammerService, ListenService, NovitaetService, RegionService, SaisonService, SortimentService } from "@app/api/filialmatrix/services";
import { ListenTypEnum, NovitätEnum, NovitätTableCol, WebapiType } from "../../common/models/novi/NovitätenTableCol";
import { LookupValue } from "@app/common/models/novi/columnLookup.model";
import { SimpleResult } from "@app/common/models/simpleResult.model";
import { GeneralResultEnum } from "@app/common/models";
import { TreeNode } from "primeng/api";
import { MWSTEnum, TimeUnitEnum } from "@app/common/enums/novitaeten";
import { JWWSTransferTypeDef, StatusTransferTypeDefaults as StatusTransferTypeDefaults } from "@app/common/models/wup/JWWS-transfertype";
import { StatusPflegeDefaults } from "@app/common/models/wup/JWWS-pflegestatus";
import { CommonCheckService } from "@app/common/services/common.validation.service";
import { VerlagDto } from "@app/common/models/verlag.model";
import { GetBranchCommentAction } from "@app/+filialmatrix/store";
import { BranchService } from "@app/api/commonWebAPI/services";



@Injectable()
export class NovitaetenLookupService {

  selectBubDataListisloading$: Observable<boolean>;


  constructor(private store: Store<any>)
  {
      this.selectBubDataListisloading$ =  this.store.pipe(select(selectNovitaetBubDataLoading))
  }

  editLookupsLoaded: { [key: string]: boolean } = {};

  selectallRegionen$ = this.store.pipe(select(selectallRegionen));
  selectallEinkaeufe$ = this.store.pipe(select(selectallEinkaeufe));
  selectallSaisons$ = this.store.pipe(select(selectallSaisons));
  selectallMarketingereignisse$ = this.store.pipe(select(selectallMarketingereignisse));
  selectallSortimente$ = this.store.pipe(select(selectallSortimente));
  selectallKlammern$ = this.store.pipe(select(selectallKlammern));
  selectallKategorien$ = this.store.pipe(select(selectallKategorien));
  selectedLipoContactsNovitaeten$ = this.store.pipe(select(selectedLipoContactsSOMA));
  selectallWarengruppen$ = this.store.pipe(select(selectallWarengruppen));
  selectallBranchGroups$ = this.store.pipe(select(selectallBranchGroups));
  selectallBranches$ = this.store.pipe(select(selectallBranches));

  selectBubDataList$ = this.store.pipe(select(selectNovitaetBubDataList));
  selectAktuellerEinkauf$ = this.store.pipe(select(selectAktuellerEinkauf));
  selectzukuenftigeEinkaeufe$ = this.store.pipe(select(selectzukuenftigeEinkaeufe));
  selectzukuenftigeEinkaeufeisLoading$ = this.store.pipe(select(selectzukuenftigeEinkaeufeisLoading));
  selectSearchedVerlage$ = this.store.pipe(select(selectSearchedVerlage));
  selectCompareTitles$ = this.store.pipe(select(selectCompareTitles));
  selectKategorienausVorabplatzierungen$ = this.store.pipe(select(selectKategorienausVorabplatzierungen));




  stichwortNodes: { [key: number]: TreeNode[] } = {};

  verlagCache: { [key: string]: VerlagDto[] } = {};

  selectedLipoUserNovitaeten$ = this.selectedLipoContactsNovitaeten$.pipe(map(m => {
    return m.map(u => <UserDto>{
      id: u.shortId,
      name: u.friendlyName,
      friendlyName: u.friendlyName,
      feedback: undefined,
      isCategoryManager: undefined
    })
  }));

  /**Editierbare Lookups mit dem ursprünglichen (eventuell gelöschten) Item  und die möglichen Auswählbaren */
  selectEditLookups(observable: Observable<any[] | LookupValue[]>, olditems: any[], idname: string): Observable<any[] | LookupValue[]> {
    return observable.pipe(map(data => {
      if (olditems == undefined || olditems.length == 0) return data;
      return [...olditems, ...data.filter(f => olditems.find(o => o[idname] == f[idname]) == undefined)];
    }))
  }


   clear(): void {
    this.store.dispatch(new ClearCommonaDataCacheAction({ clearstichwortList: true, clearsortimentStruktur: true }));
    this.editLookupsLoaded = {};
    this.verlagCache = {};
  }


  init(listofFields: NovitätTableCol[]): void {

    listofFields.filter(f => f.weapitype == WebapiType.idName).forEach(f => {
      this.loadLookupIdnameFilterData(f.field);
    })


    listofFields.filter(f => f.weapitype == WebapiType.string).forEach(f => {
      this.loadLookupStringFilterData(f.field);
    })

    listofFields.filter(f => f.weapitype == WebapiType.int).forEach(f => {
      this.loadLookupIntFilterData(f.field);
    })
  }

  /**Vergleichsdaten mit caching laden  -> 429 Error vermeiden*/
  loadVergleichsDaten(novitaet: NovitaetDto): void {
    if ( novitaet && (novitaet.vergleichsartikel == undefined || novitaet.vergleichsartikel.length == 0)) {
      this.store.dispatch(new GetCompareListActionDone({ noviwupId: novitaet.id, compareTitles: [] }));
      return;
    }
    this.selectCompareTitles$.pipe(take(1)).subscribe(vergleiche => {
      if (vergleiche == undefined)  return ;
      var data = vergleiche[novitaet.id];
      if (data == undefined || data.length != novitaet.vergleichsartikel.length) {
        this.store.dispatch(new GetCompareListAction({ novitaet: novitaet }));
      }
    }).unsubscribe()
  }


  /**Kategorien aus der Vorabplatzierungen laden */
  loadKategorienfromVorabplatzierung(sortimentid: number): void {
    if (sortimentid == null) return;
    this.selectKategorienausVorabplatzierungen$.pipe(take(1)).subscribe(dict => {
      if (dict == undefined || dict[sortimentid] == undefined) {
        this.store.dispatch(new GetKategorienderVorabplatzierungen({ sortimentid: sortimentid }))
      }
    })
  }


  /**Bubdaten lookups */
  async loadbubDaten(eans: string[]) {

    this.selectBubDataList$.pipe(take(1)).subscribe(bubdata => {
      var resteans = eans.filter(e => bubdata.filter(f => f.ean == e).length == 0);
      if (resteans.length > 0) {
        this.store.dispatch(new GetNovitaetBubDataByEansAction({ eans: resteans }));
      }
    })
  }


  loadZukuenftigeEinkaeufe(cache: boolean) {

    this.selectzukuenftigeEinkaeufe$.pipe(take(1)).subscribe(zeinkaufe => {
      if (cache == true && zeinkaufe && zeinkaufe.length > 0) {
        return;
      }
      var queryToken = <QueryTokenDTO>{ hitsOnly: false };
      let today = new Date();
      queryToken.filter = {
        "stop.>": today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      }
      var pr = <EinkaufService.EinkaufQueryEinkaufParams>{}
      pr.eagerLoading = 2;
      pr.queryToken = queryToken;
      this.store.dispatch(new GetZukuenftigeEinkaufAction({ params: pr }));
    })
  }


  loadAktuellerEinkauf() {

    var queryToken = <QueryTokenDTO>{ hitsOnly: false };
    let today = new Date();

    queryToken.filter = {
      "start.<=": today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
      "stop.>=": today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
    }
    var pr = <EinkaufService.EinkaufQueryEinkaufParams>{}
    pr.eagerLoading = 2;
    pr.queryToken = queryToken;
    this.store.dispatch(new GetAktuellenEinkaufAction({ params: pr }));
  }


  /**Lädt alle konkreten Dtos zum Editieren */
  loadEditLookupFilterData(type: NovitätEnum, checkifLoaded: boolean) {
    if (type == undefined)
    {
      throw Error("Unbekannter Type NovitätEnum für lookups ")
    }

    if (checkifLoaded && this.editLookupsLoaded[type.toString()] != undefined) return;
    var queryToken = <QueryTokenDTO>{ hitsOnly: false };
    var eagerloading = 2;
    var filter = {
      "take": null,
      "skip": null,
      "input": null,
      "orderBy": null
    };
    switch (type) {


      case NovitätEnum.wupzielliste:

        filter["typ.equals"] = ListenTypEnum.ZiellisteWup.toString();
        queryToken.filter = filter;
        var listp = <ListenService.ListenQueryListeParams>
          {
            queryToken: queryToken
          }
        this.store.dispatch(new GetNovitaetenListenAction({ typ: ListenTypEnum.ZiellisteWup, param: listp }));
        this.editLookupsLoaded[type.toString()] = true;
        break;


      case NovitätEnum.artikel_product_productgroup:
      case NovitätEnum.artikel_product_productgroup:
        this.store.dispatch(new GetNovitaetenWarengruppenAction({}));
        this.editLookupsLoaded[type.toString()] = true;
        break;

      case NovitätEnum.lipocontacts:
        this.store.dispatch(new GetNovitaetenSoMaContactsAction({}));
        this.editLookupsLoaded[type.toString()] = true;
        break;

      case NovitätEnum.kategorien:
        this.store.dispatch(new GetNovitaetenKategorienAction({}));
        this.editLookupsLoaded[type.toString()] = true;
        break;

      case NovitätEnum.sortimente:
        var ps = <SortimentService.SortimentQuerySortimenteParams>{}
        ps.eagerLoading = eagerloading;
        ps.queryToken = queryToken;
        ps.queryToken.filter = filter;
        this.store.dispatch(new GetSortimenteAction({ param: ps }));
        this.editLookupsLoaded[type.toString()] = true;
        break;

      case NovitätEnum.klammern:
        var pk = <KlammerService.KlammerQueryKlammernParams>{};
        pk.eagerLoading = eagerloading;
        pk.queryToken = queryToken;
        pk.queryToken.filter = filter;
        this.store.dispatch(new GetKlammernAction({ param: pk }));
        this.editLookupsLoaded[type.toString()] = true;
        break;

      case NovitätEnum.artikel_region:
        var pr = <RegionService.RegionQueryRegionenParams>{}
        pr.eagerLoading = eagerloading;
        pr.queryToken = queryToken;
        pr.queryToken.filter = filter;
        this.store.dispatch(new GetRegionenAction({ params: pr }));
        this.editLookupsLoaded[type.toString()] = true;
        break;

      case NovitätEnum.artikel_saison:
        var pr = <SaisonService.SaisonQuerySaisonParams>{}
        pr.eagerLoading = eagerloading;
        pr.queryToken = queryToken;
        pr.queryToken.filter = filter;
        this.store.dispatch(new GetSaisonsAction({ params: pr }));
        this.editLookupsLoaded[type.toString()] = true;
        break

      case NovitätEnum.marketing_ereignisse:
        var pr = <EreignisService.EreignisQueryEreignisParams>{}
        pr.eagerLoading = eagerloading;
        pr.queryToken = queryToken;
        pr.queryToken.filter = filter;
        this.store.dispatch(new GetMarketingEreignisseAction({ params: pr }));
        this.editLookupsLoaded[type.toString()] = true;
        break

      case NovitätEnum.einkauf:
        var pr = <EinkaufService.EinkaufQueryEinkaufParams>{}
        pr.eagerLoading = eagerloading;
        pr.queryToken = queryToken;
        pr.queryToken.filter = filter;
        pr.queryToken.orderBy = [<ExtendedOrderByDTO>{ by: 'start', desc: true }];
        this.store.dispatch(new GetEinkaeufeAction({ params: pr }));
        this.editLookupsLoaded[type.toString()] = true;
        break


        case NovitätEnum.branch:
        var pb = <BranchService.BranchQueryBranchParams>{};
        pb.eagerLoading = eagerloading;
        pb.queryToken = queryToken;
        pb.queryToken.filter = filter;
        this.store.dispatch(new GetBranchesAction({ params: pb }));
        this.editLookupsLoaded[type.toString()] = true;
        break;


        case NovitätEnum.branchgroup:
          var pb = <BranchService.BranchQueryBranchGroupParams>{};
          pb.eagerLoading = eagerloading;
          pb.queryToken = queryToken;
          pb.queryToken.filter = filter;
          this.store.dispatch(new GetBranchGroupsAction({ params: pb }));
          this.editLookupsLoaded[type.toString()] = true;
          break;

      default:
        this.store.dispatch(new CommonDataErrorAction({
          type: CommonDataActionType.GetNovitaetenLookupsIdNameActionDone, message: "Fehler Bearbeitungsdaten laden.",
          error: <SimpleResult>{ userMessage: 'Edit values not defined for ' + type, generalResult: GeneralResultEnum.OnlyDebug, error: new Error('Editvalues not defined ' + type) }
        }));

    }
  }

  getAdvLookups(type: NovitätEnum): Observable<LookupValue[]> {
    return this.store.select(selectNovitaetenLookupsbyKey(type));
  }


  getListenDtos(typ: ListenTypEnum): Observable<ListeDto[]> {
    return this.store.pipe(select(selectListenTypenbyKey(typ))).pipe(map(d => {
      var result = new Array<ListeDto>();
      if (d != undefined) {
        result = [...d];
      }

      result.unshift(<ListeDto>{ typ: typ, name: 'Nachlistung' });
      return result;
    }
    ))
  }




  /**Laden aus der ADV */
  loadLookupIdnameFilterData(fld: NovitätEnum): void {
    if (fld == undefined) return;
    var field = fld.replaceAll("_", ".");
    this.store.select(selectNovitaetenLookupsbyKey(field)).pipe(take(1)).subscribe(f => {
      if (f == undefined || f.length == 0) {
        var p = <NovitaetService.NovitaetQueryNovitaetenIdnameLookupParams>{
          key: field,
          queryToken: {}
        }
        this.store.dispatch(new GetNovitaetenLookupsIdNameAction({ params: p }));
      }
    })
  }

  loadLookupIntFilterData(fld: NovitätEnum): void {
    if (fld == undefined) return;

    this.store.select(selectNovitaetenLookupsbyKey(fld)).pipe(take(1)).subscribe(f => {
      if (f == undefined || f.length == 0) {
        var p = <NovitaetService.NovitaetQueryNovitaetenIntLookupParams>{
          key: fld,
          queryToken: {}
        }
        this.store.dispatch(new GetNovitaetenLookupsIntAction({ params: p }));
      }
    })
  }



  loadLookupStringFilterData(fld: NovitätEnum): void {
    if (fld == undefined) return;

    this.store.select(selectNovitaetenLookupsbyKey(fld)).pipe(take(1)).subscribe(f => {
      if (f == undefined || f.length == 0) {
        var p = <NovitaetService.NovitaetQueryNovitaetenStringLookupParams>{
          key: fld,
          queryToken: {}
        }
        this.store.dispatch(new GetNovitaetenLookupsStringAction({ params: p }));
      }
    })
  }



  getPossibleMWST(): LookupValue[] {
    var arr = new Array<LookupValue>();
    var keys = Object.keys(MWSTEnum).filter(x => (parseInt(x) >= 0));
    keys.forEach(k => {
      arr.push(<LookupValue>{ id: Number(k), value: MWSTEnum[k].toString(), valueName: '', name: '' });
    })
    return arr;
  }


  /**Timeunits als LookupValues */
  getPossibleZeiteinheiten(): LookupValue[] {
    var arr = new Array<LookupValue>();
    var keys = Object.keys(TimeUnitEnum).filter(x => (parseInt(x) >= 0));
    keys.forEach(k => {
      arr.push(<LookupValue>{ id: Number(k), value: TimeUnitEnum[k].toString(), valueName: '', name: '' });
    })
    return arr;
  }

  getTransferTypes(): JWWSTransferTypeDef[] {
    return StatusTransferTypeDefaults.getValidList();
  }

  getPflegeStati(): LookupValue[] {
    return StatusPflegeDefaults.getValidList().map(f => <LookupValue>{ id: f.value, value: f.text });
  }

  /**LDV Verlage holen
   * Vorher wird das Ergebnis entfernt
   * Ergebnis =>  selectSearchedVerlage$
  */
  getLDVVerlage(srch: string): void {

    if (srch == undefined) {
      this.store.dispatch(new GetVerlagebySearchActionDone({ result: [] }));
      return;
    }

    srch = srch.trim();
    if (this.verlagCache[srch] != undefined && this.verlagCache[srch] instanceof Array) {
      this.store.dispatch(new GetVerlagebySearchActionDone({ result: [...this.verlagCache[srch]] }));
    } else {
      this.store.dispatch(new GetVerlagebySearchActionDone({ result: [] }));
      this.store.dispatch(new GetVerlagebySearchAction({ srch: srch }));
      this.selectSearchedVerlage$.pipe(filter(f => f != undefined && f.length > 0), take(1)).subscribe(d => {
        this.verlagCache[srch] = d;
      });
    }
  }


}
