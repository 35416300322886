import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { ValidationListColumn } from '@app/common/models';
import { Store, select } from '@ngrx/store';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ColumnTypes, NumberRangeValue, NumberValue, TextValue, DateRangeValue, BoolValue, ListValue, DateValue, DynListValue as DynamicListValue, DynListOption } from '@app/common/models/columnBase.model';
import { filter } from 'rxjs';
import { ISetting, ISettingChildrenValue, ISettingValue, SettingValueEnum, SettingValueTypeEnum } from '@app/common/models/setting.models';


import { CommonCheckService } from '@app/common/services/common.validation.service';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { GetSettingsChildrenAction, GetSettingsStateAction, selectSettingChildrenValues, selectSettingValues } from '@app/common-data-module/store';

@Component({
  selector: 'app-editvalidationcolumn-view',
  templateUrl: 'editValidationColumn.dialog.component.html',
  styleUrls: ['./dialogs.component.scss'],
})

export class EditValidationColumnDialogComponent implements OnInit {

  validationColumnToView: ValidationListColumn;
  numberRangeValue: NumberRangeValue = { numberrangestart: 0, numberrangeend: 0 };
  dateRangeValue: DateRangeValue = { daterangestart: new Date, daterangeend: new Date };
  numberValue: NumberValue = { maxdecimals: 0, maxdigits: 0, isnumberonly: false };
  textValue: TextValue = { maxlength: 120, validregex: null };
  listValue: ListValue = { valuelist: [] };
  checkBoxValue: BoolValue = { value: null };
  dateValue: DateValue = { value: null };
  dynListOptions: DynamicListValue[] = [];
  dynmlistValue: DynamicListValue =   null


  colTypeOptions: { label: string, value: number }[] = [];
  colEnum: any;
  datePickerOpen = false;

  settingList: WritableSignal<ISetting[]> = signal([]);
  settingValueList: WritableSignal<ISettingValue[]> = signal([]);
  selecteddbMapping: WritableSignal<ISettingValue> = signal(null);
  selectedChildren: WritableSignal<{ [key: string]: ISettingChildrenValue[] }> = signal({});
  hint: WritableSignal<string> = signal(null);


  constructor(
    private store: Store<any>,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private validater: CommonCheckService
  ) { }

  ngOnInit() {

    const keys1 = Object.keys(DynListOption).filter(key => !isNaN(DynListOption[key]));
    keys1.forEach(x => this.dynListOptions.push({ label: x, value: DynListOption[x] }));
    this.dynmlistValue = this.dynListOptions.find(f => f.value == 1);

    this.validationColumnToView = this.config.data.validationColumnToView;
    if (this.validationColumnToView.validationRule) {
      switch (this.validationColumnToView.colType) {
        case (ColumnTypes.Text):
          this.textValue = JSON.parse(this.validationColumnToView.validationRule);
          break;
        case (ColumnTypes.DateRange):
          this.dateRangeValue = JSON.parse(this.validationColumnToView.validationRule);
          break;
        case (ColumnTypes.NumberRange):
          this.numberRangeValue = JSON.parse(this.validationColumnToView.validationRule);
          break;
        case (ColumnTypes.List):
          this.listValue = JSON.parse(this.validationColumnToView.validationRule);
          break;
        case (ColumnTypes.Number):
          this.numberValue = JSON.parse(this.validationColumnToView.validationRule);
          break;
        case (ColumnTypes.CheckBox):
          this.checkBoxValue = JSON.parse(this.validationColumnToView.validationRule);
          break;
        case (ColumnTypes.Date):
          this.dateValue = JSON.parse(this.validationColumnToView.validationRule);
          break;
        case (ColumnTypes.DynamicList):
          let result = JSON.parse(this.validationColumnToView.validationRule);
          this.dynmlistValue = { ...this.dynListOptions.find(f => f.value == result.value) };
          break;
      }
    }

    const keys = Object.keys(ColumnTypes).filter(key => !isNaN(ColumnTypes[key]));
    keys.forEach(x => this.colTypeOptions.push({ label: x, value: ColumnTypes[x] }));

    this.colEnum = ColumnTypes;



    this.store.dispatch(new GetSettingsStateAction({ settingid: SettingValueEnum.DBMapping, onlyActive: true }));

    this.store.pipe(select(selectSettingValues)).pipe(filter(f => f && f.length > 0)).subscribe(d => {
      if (this.validationColumnToView.dbMapping && !d.find(f => f.settingValue == this.validationColumnToView.dbMapping)) {
        d = this.validater.getClone(d);
        var current = <ISettingValue>{ id: 0, setting_Id: SettingValueEnum.DBMapping, settingValue: this.validationColumnToView.dbMapping, isActive: 1 };
        d.unshift(current);
        this.hint.set('Die Datenbankzuordnung wurde nicht gefunden. ');
      }

      this.settingValueList.set(d);
      this.selecteddbMapping.set(d.find(f => f.settingValue == this.validationColumnToView.dbMapping));
      if (this.selecteddbMapping()) {
        this.changeDbMapping({ value: this.selecteddbMapping(), originalEvent: null }, true);
      }

    }
    )

  }


  changeDynList(e) {
    this.dynmlistValue = e.value;
  }

  clearDbMapping() {
    this.validationColumnToView = this.validater.getClone(this.validationColumnToView);
    this.validationColumnToView.colType = ColumnTypes.Text;
    this.validationColumnToView.dbMapping = null;
    this.validationColumnToView.bubMapping = null;
  }

  changeDbMapping(e: DropdownChangeEvent, init: boolean = false) {

    this.hint.set(null);
    this.validationColumnToView = this.validater.getClone(this.validationColumnToView);
    this.validationColumnToView.dbMapping = e.value.settingValue;
    this.validationColumnToView.bubMapping = null;
    if (this.selectedChildren()[e.value.id] == undefined || this.selectedChildren()[e.value.id].length == 0) {
      this.store.dispatch(new GetSettingsChildrenAction({ settingvalueId: e.value.id }));
    }

    this.store.pipe(select(selectSettingChildrenValues)).subscribe(data => {
      var elements = data[e.value.id];
      if (elements instanceof Array) {

        this.validationColumnToView = this.validater.getClone(this.validationColumnToView);
        var coltype = elements.find(x => x.name == 'columntype');
        if (coltype && coltype.settingValue) {
          this.validationColumnToView.colType = Number(coltype.settingValue) as ColumnTypes;
        }
        var bub = elements.find(x => x.name == 'bubmapping');
        if (bub && bub.settingValue) {
          if (init &&  this.validationColumnToView.bubMapping != bub.settingValue) {
            this.hint.set('BubMapping wurde geändert');
          }
          if (this.validationColumnToView.dbMapping == e.value.settingValue )
          {
            this.validationColumnToView.bubMapping = bub.settingValue;
          }
        }
        var desc = elements.find(x => x.name == 'description');
        if (desc && desc.settingValue && !init) {
          this.validationColumnToView.colDesc = desc.settingValue;
        }
      }
    })

  }

  close() {
    switch (this.validationColumnToView.colType) {
      case (ColumnTypes.Text):
        if (this.textValue) this.validationColumnToView.validationRule = JSON.stringify(this.textValue);
        break;
      case (ColumnTypes.DateRange):
        if (this.dateRangeValue) this.validationColumnToView.validationRule = JSON.stringify(this.dateRangeValue);
        break;
      case (ColumnTypes.NumberRange):
        if (this.numberRangeValue) this.validationColumnToView.validationRule = JSON.stringify(this.numberRangeValue);
        break;
      case (ColumnTypes.List):
        if (this.listValue) this.validationColumnToView.validationRule = JSON.stringify(this.listValue);
        break;
      case (ColumnTypes.Number):
        if (this.numberValue) this.validationColumnToView.validationRule = JSON.stringify(this.numberValue);
        break;
      case (ColumnTypes.CheckBox):
        if (this.checkBoxValue) this.validationColumnToView.validationRule = JSON.stringify(this.checkBoxValue);
        break;
      case (ColumnTypes.Date):
        if (this.dateValue) this.validationColumnToView.validationRule = JSON.stringify(this.dateValue);
        break;
      case (ColumnTypes.DynamicList):
        if (this.dynmlistValue) this.validationColumnToView.validationRule = JSON.stringify(this.dynmlistValue);
        break;

    }
    this.ref.close(this.validationColumnToView);
  }

  cancel() {
    this.ref.close();
  }

  deleteListValue(itemValue: string) {
    const idx = this.listValue.valuelist.findIndex(x => x.item == itemValue);
    this.listValue.valuelist.splice(idx, 1);
  }

  addNewListValue() {
    this.listValue.valuelist.push({ item: "Neuer Eintrag" });
  }
}
