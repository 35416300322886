
import { EventEmitter, Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import * as isEqual from 'lodash/fp/isEqual';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { FileDescriptionModel, FileModel, GeneralResultEnum } from '../models';
import { LipoValidator, LipoValidators, NoviColumn, NoviColumnBase } from '../models/novi/noviColumns.model';
import { SimpleResult, SimpleResultError } from '../models/simpleResult.model';
import { SeverityType, ValidationColumnModel, ValidationError, ValidationModel } from '../models/validation.model';
import { NonNullAssert } from '@angular/compiler';
import { LookupValue, LookupValueBase } from '../models/novi/columnLookup.model';
import { KeyValue } from '@angular/common';


/**Allgemeiner Service für Checks und Validierung
 * Clientseitig
 */
@Injectable()
export class CommonCheckService {

  today = new Date(new Date().setHours(0, 0, 0, 0));


  /**Daten des heutigen tages aus einem Array ermittelnt
   * und sortieren nac STartDAte
  */
  getinToday<T>(typed: Array<T>, startDate: string, stopDate: string): Array<T> {

    try {
      var result = typed.filter(f => (this.getOnlyDate(f[startDate]) <= this.today
        && (!f[stopDate] || this.getOnlyDate(f[stopDate]) >= this.today))).sort((a, b) => (a[startDate] > b[startDate] ? -1 : 1));
      return result;
    } catch (e) {
      this.onError?.emit(<SimpleResult>{ userMessage: 'Heutige Daten aus Array filtern', generalResult: GeneralResultEnum.OnlyDebug })
      return typed;
    }
  }


  /**Blob laden und im Browser anzeigen */
  loadFileFromBlob(filemodel: FileModel): void {
    try {
      if (filemodel == undefined) return;
      if (filemodel.desciption.fileType == undefined) throw new Error("Datei Typ ist unbekannt");
      if (filemodel.blob == undefined) throw new Error("Datei ist leer");
      const file = new Blob([filemodel.blob], { type: filemodel.desciption.fileType })

      const url = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.target = "_self";
      a.href = url;
      a.download = `${filemodel.desciption.fileName}.${filemodel.desciption.fileExtension}`;
      a.click();
      a.remove();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(url);
      }, 100);
    } catch (e) {
      this.onError?.emit(<SimpleResult>{ userMessage: 'Downloadfehler ' + filemodel.desciption.fileName, generalResult: GeneralResultEnum.GeneralError })
      return;
    }
  }


  /**Datum auf Datum ohne Zeit konvertieren */
  getOnlyDate(date: Date) : Date {
    try {
      if (!this.isDate(date)) { return null; };
      var d = new Date(date);
      d.setHours(0, 0, 0, 0);;
      return d;
    } catch (e) {
      this.onError?.emit(<SimpleResult>{ userMessage: 'Datum konvertieren', generalResult: GeneralResultEnum.OnlyDebug })
      return date;
    }
  }



  onError = new EventEmitter<SimpleResult>();

  /**FullDate aus ISoString ermitteln  */
  getOnlyDatefromISOString(dateasISOstring: string, defaultdate: Date = undefined): Date {
    try {
      if (dateasISOstring == undefined) return defaultdate;
      var parse = Date.parse(dateasISOstring);
      var d = new Date(parse);
      d.setHours(0, 0, 0, 0);
      return d;
    } catch (e) {
      this.onError?.emit(<SimpleResult>{ userMessage: 'Datum konvertieren', generalResult: GeneralResultEnum.OnlyDebug })
      return defaultdate;
    }
  }

  tolocaleNumber(numbvalue: number, fractionDigits: number = 0): string {
    if (numbvalue == undefined) return null;
    let result = numbvalue.toLocaleString("de-DE", { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });
    return result;
  }

  /**into Number z.b. von deutschem String Format aus 12.000,30 -> 12000.30   */
  toNumber(anyNumber: any, thousandSeparator: string = '.', decimalSeparator: string = ','): Number {
    if (anyNumber == undefined) return 0;
    if (typeof anyNumber === 'number') return anyNumber;
    //deutsches Trennzeichen raus
    return parseFloat(anyNumber
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + decimalSeparator), '.')
    );
  }


  /**Liste einer normalen Enumeration, key/value number */
  getArrayofEnum(enumme): LookupValueBase[] {
    const StringIsNumber = value => isNaN(Number(value)) === false;
    return Object.keys(enumme)
      .filter(StringIsNumber)
      .map(key => <LookupValueBase>{ id: Number(key), value: enumme[key] });
  }



  /**Alle Unter-Objects eines js-objects*/
  getAllSubObjects(obj: any): any[] {
    return Object.values(obj).filter(v => this.isObject(v));
  }

  /**Klonen, nur wenn nötig
   * wenn readonly/inextensible, alle Subobjects werden geprüft*/
  getClone(obj: any): any {
    if (obj == undefined) return obj;
    if ((Object.isFrozen(obj) || !Object.isExtensible(obj) || this.getAllSubObjects(obj).find(o => !Object.isExtensible(o) || Object.isFrozen(o)) != undefined)) {
      return cloneDeep(obj);
    }
    return obj;
  }

  /**Vergleich zweier Objekte */
  isEqual(object1, object2) {
    return isEqual(object1, object2);
  }


  isObject(object) {
    return object != null && typeof object === 'object';
  }

  validateSingle(id: any, property: string, propertyname: string, value: any, validations: LipoValidator[], allValues: any[]): ValidationModel {
    var result = new ValidationModel()
    validations.forEach(val => {
      switch (val.lipoValidator) {
        case LipoValidators.required:
          result.id = id;
          if (val.notallowed && val.notallowed instanceof Array) {
            for (let index = 0; index < val.notallowed.length; index++) {
              const element = val.notallowed[index];
              if (value != undefined && element == value) {
                result.errors.push(<ValidationError>{ severity: SeverityType.Error, propertyName: property, errorMessage: "Wert für " + propertyname + "  ist nicht gültig." })
                result.message = result.errors && result.errors.length > 0 ? result.errors[0].errorMessage : '';
                return result;
              }
            }
          }
          if (value == undefined || value.toString().trim().length == 0) {
            result.errors.push(<ValidationError>{ severity: SeverityType.Error, propertyName: property, errorMessage: propertyname + " ist ein Pflichtfeld" })
          } else if (typeof value == 'number' && Number(value) == 0) {
            result.errors.push(<ValidationError>{ severity: SeverityType.Error, propertyName: property, errorMessage: propertyname + " ist ein Pflichtfeld." })
          } else if (value != undefined && value instanceof Array && value.length == 0) {
            result.errors.push(<ValidationError>{ severity: SeverityType.Error, propertyName: property, errorMessage: propertyname + " ist ein Pflichtfeld" })
          }
          break;
        case LipoValidators.nodublicates:
          result.id = id;
          if (allValues instanceof Array) {
            var dublicate = [];
            for (let index = 0; index < allValues.length; index++) {
              const row = allValues[index];
              if (this.getDeepValue(row, property) == value) {
                dublicate.push(value);
              }
              if (dublicate.length > 1) break;
            }
            if (dublicate.length > 1) {
              result.errors.push(<ValidationError>{ severity: SeverityType.Error, propertyName: property, errorMessage: propertyname + " ist nicht eindeutig " })
            }
          }


          break;
        default:
          this.onError?.emit(<SimpleResult>{ userMessage: val + ' ist keine gültige Validierung ', generalResult: GeneralResultEnum.OnlyDebug })
      }
    })


    result.message = result.errors && result.errors.length > 0 ? result.errors[0].errorMessage : '';

    return result;
  }


  /**Validation für NoviColumn
   *TODO: nur required, nodublicate zur Zeit
   */
  validate(col: NoviColumnBase | NoviColumn, data: any, lDataKey: string, allValues: any[]): ValidationColumnModel {
    if (data == undefined) return new ValidationColumnModel(col.field);
    if (col.validations == undefined) return new ValidationColumnModel(col.field);
    if (lDataKey == undefined) return new ValidationColumnModel(col.field);
    if (data[lDataKey] == undefined) return new ValidationColumnModel(col.field);
    var result = new ValidationColumnModel(col.field, data[lDataKey])
    col.validations.forEach(val => {
      switch (val) {
        case LipoValidators.required:
          result.id = data[lDataKey];
          var value = this.getDeepValue(data, col.field);
          if (value == undefined || value.toString().trim().length == 0) {
            result.errors.push(<ValidationError>{ severity: SeverityType.Error, propertyName: col.field, errorMessage: col.header + " ist ein Pflichtfeld" })
          } else if (typeof value == 'number' && Number(value) == 0) {
            result.errors.push(<ValidationError>{ severity: SeverityType.Error, propertyName: col.field, errorMessage: col.header + " muß größer 0 sein." })
          } else if (value != undefined && value instanceof Array && value.length == 0) {
            result.errors.push(<ValidationError>{ severity: SeverityType.Error, propertyName: col.field, errorMessage: col.header + " ist ein Pflichtfeld" })
          }
          break;
        case LipoValidators.nodublicates:
          result.id = data[lDataKey];
          var value = this.getDeepValue(data, col.field);
          if (value == undefined || value.toString().trim().length == 0) return result;
          if (allValues) {
            var novicol = <NoviColumn>col;
            if (novicol.dropDown && novicol.dropDown.optionLabel) {
              let dublicate = allValues.find(f => f[lDataKey] != data[lDataKey] && f[col.part ? col.part + "." + col.field : col.field]
                && f[col.part ? col.part + "." + col.field : col.field][novicol.dropDown.optionLabel] == value[novicol.dropDown.optionLabel]);
              if (dublicate) {
                result.errors.push(<ValidationError>{ severity: SeverityType.Error, propertyName: col.field, errorMessage: col.header + " ist nicht eindeutig " })
              }

            } else {
              let dublicate = allValues.find(f => f[lDataKey] != data[lDataKey] && f[col.part ? col.part + "." + col.field : col.field]
                && f[col.part ? col.part + "." + col.field : col.field].toString() == value.toString());
              if (dublicate) {
                result.errors.push(<ValidationError>{ severity: SeverityType.Error, propertyName: col.field, errorMessage: col.header + " ist nicht eindeutig " })
              }
            }
          }

          break;
        default:
          this.onError?.emit(<SimpleResult>{ userMessage: val + ' ist keine gültige Validierung ', generalResult: GeneralResultEnum.OnlyDebug })

      }
    })


    result.message = result.errors && result.errors.length > 0 ? result.errors[0].errorMessage : '';

    return result;
  }

  /**Verschachtelte Valuewert ermitteln z.b. aus artikel.masse.length*/
  getDeepValue(data: any, field: string) {
    let deepdown = field.split('.');
    if (deepdown.length == 0) {
      return data[field];
    }
    var obj = data;
    for (var i = 0, path = deepdown, len = path.length; i < len; i++) {
      obj = obj[path[i]];
      if (obj == undefined) return obj;
    };
    return obj;
  }

  /**vernünftiges Datum zurückgeben als Text , pipe ähnlich */
  toDateText(d: Date): string {
    if (!this.isDate(d)) return '';
    return d.toLocaleDateString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }


  /**Prüfen ob Datum */
  isDate(anydate: any) {
    if (anydate == undefined) return false;
    var trydate = new Date(anydate);
    return trydate instanceof Date && !isNaN(Number(trydate));
  }

  /**uniqueids zwischen speichern */
  newIdBasket: number[] = [];


  /**einfach eine Unique Number zurückgeben
   * für Neuabnlage z.b.
  */
  getUniqueId(): number {
    var uiid = ((new Date()).getTime());
    while (this.newIdBasket.find(g => g == uiid) != undefined) {
      uiid = ((new Date()).getTime());
    }
    this.newIdBasket.push(uiid);
    return uiid;
  }


  /**ähnlich wie JSON.stringify() */
  stringify(error: any): string {
    try {

      if (error == undefined) return "";
      var result = "";
      let keys = new Set([...Object.keys(error), ...Object.getOwnPropertyNames(error)]);
      keys.forEach(k => {
        result += k + "\t " + (this.isObject(error[k]) ? this.stringify(error[k]) : error[k]) + "\r\n ";
      })
      return result;
    } catch (e) {
      this.onError?.emit(<SimpleResult>{ userMessage: 'JSON stringify ', serverMessage: e.toString(), generalResult: GeneralResultEnum.OnlyDebug })
      return "";
    }
  }


  constructor() { }


  /**Date as string für Server  */
  getDateasString(date: Date): string {
    if (date == undefined) return null;
    if (!this.isDate(date)) return null;
    var newdate = new Date(date);
    return newdate.getFullYear() + "-" + (newdate.getMonth() + 1) + "-" + newdate.getDate();
  }



  /**Kurzfrom der Userid  */
  getShortUserid(longuserid: string): number {
    if (longuserid == undefined) return null;
    if (longuserid && Number(longuserid) > 230000000000000000) {
      var result = Number(longuserid.substring(3))
      return result;
    }
    return null
  }
  getValidFieldName(filename: string): string {
    return filename.replaceAll(/[/\\?%*:|"<> ]/g, '-');
  }


  /**Dateiname nur mit gültigen Zeichen */
  getValidFileName(filename: string): string {
    return filename.replaceAll(/[/\\?%*:|"<>]/g, '-');
  }

  //https://stackoverflow.com/questions/13605340/how-to-validate-a-ean-gtin-barcode-in-javascript
  /**Check Ean code */
  isValidBarcode(value: string): boolean {
    // We only allow correct length barcodes
    if (!value.match(/^(\d{8}|\d{12,14})$/)) {
      return false;
    }

    const paddedValue = value.padStart(14, '0');

    let result = 0;
    for (let i = 0; i < paddedValue.length - 1; i += 1) {
      result += parseInt(paddedValue.charAt(i), 10) * ((i % 2 === 0) ? 3 : 1);
    }

    return ((10 - (result % 10)) % 10) === parseInt(paddedValue.charAt(13), 10);
  }


  /**Ean prüfen und Fehler ermitteln */
  getEanError(ean: string, lengthMin: number, checkdigit: boolean = false): SimpleResultError {
    try {
      var result = <SimpleResultError>{ generalResult: GeneralResultEnum.Success };
      if (ean == undefined || ean.length == 0) {
        result.generalResult = GeneralResultEnum.GeneralError;
        result.userMessage = "EAN ist leer";
        return result;
      }
      var ean = this.transformEan(ean);
      if (ean.length > 17) {
        result.generalResult = GeneralResultEnum.GeneralError;
        result.userMessage = "EAN hat falsche Länge";
        return result;
      }
      if (ean.length < lengthMin) {
        result.generalResult = GeneralResultEnum.GeneralError;
        result.userMessage = "EAN ist unvollständig";
        return result;
      }
      for (let index = 0; index < ean.length; index++) {
        const element = ean[index];
        var testNumber = Number(element);
        if (testNumber == undefined || Number.isNaN(testNumber) || testNumber < 0) {
          result.generalResult = GeneralResultEnum.GeneralError;
          result.userMessage = "EAN muss numerisch sein. (" + element + ")";
          return result;
        }
      }
      if (checkdigit) {
        var valid = this.isValidBarcode(ean);
        if (!valid) {
          result.generalResult = GeneralResultEnum.GeneralError;
          result.userMessage = "EAN ist nicht konsistent";
          return result;
        }
      }
    } catch (e) {
      result.generalResult = GeneralResultEnum.Success; // Fehler aber Ablauf nicht verhidnern
      result.serverMessage = e;
      result.userMessage = "Error in CheckService";
    }
    return result;
  }

  /**Ean Tranformieren der erlaubten Zeichen
   * nicht numerisch !
   */
  arraycharacter = ["-", "-", "—", "/n", "/r", " "];
  transformEan(ean: string): string {
    /**alle Arten von Bindestriche und andere sind erlaubt und werden aber entfernt */
    if (!ean) return "";
    if (ean.length == 0) return "";
    for (let index = 0; index < this.arraycharacter.length; index++) {
      const element = this.arraycharacter[index];
      while (ean.indexOf(element) > -1) {
        ean = ean.replaceAll(element, "");
      }
    }
    return ean;
  }


  /**TEST AUFRUF */
  getINVALIDRESPONSETEST(obj: any): any {
    obj.invalidProperties = {};
    var keys = Object.keys(obj);
    keys.forEach(k => {
      obj.invalidProperties[k] = " Fehler in " + k;
    });

    return obj;
  }


  clear() {
    this.newIdBasket = [];
  }

  /**Aus einem String oder Array eine Liste von sTring bauen
   * z.b. copy aus Excel
   * anyValue = "a b c" oder "a,b,c" oder "a\nb\nc" oder ["a","b","c"]
    */
  getStringList(anyvalue: any): Array<string> {
    try {
      if (anyvalue == undefined) return [];
      if (anyvalue && (anyvalue instanceof Array) == false) {
        // split by ein oder mehrere Leerzeichen
        var list = [...new Set<string>(anyvalue.split(/\s+/).map(m => m.trim()))];
        if (list instanceof Array) return list;
        if (anyvalue.indexOf(/\r\n/) > -1) // split by Zeilenumbruch
        {
          var listreturn = [...new Set<string>(anyvalue.split(/\r\n/).map(m =>  m.trim()))];
          if (listreturn instanceof Array) return listreturn;
        }
        var listcomma = [...new Set<string>(anyvalue.split(", ").map(m => m.trim()))];
        if (listcomma instanceof Array) return listcomma;
      }
    } catch (e) {
      this.onError?.emit(<SimpleResult>{ userMessage: 'Stringliste erzeugen', generalResult: GeneralResultEnum.OnlyDebug })
    }
    return anyvalue instanceof Array ? anyvalue : [anyvalue];
  }

}
