<div class="container" style="overflow: auto;" >
<div class="grid">
  <div class="col-5" style="padding-right: 1.25rem !important;">
    <div class="p-formgrid grid">
      <div class="field col-12">
        <label for="aufgabenart"  style="width: 10rem;">Aufgabenart</label>
        <p-dropdown id="aufgabenart" [options]="aufgabenArten" [(ngModel)]="selectedAufgabenart" optionLabel="value"
          [disabled]="!canSelectAufgabenArt()" placeholder="Aufgabenart wählen" (ngModelChange)="changedAufgabenart()"
          [style]="{'max-width': '15rem', 'width': '15rem'}">
        </p-dropdown>
      </div>

      <div class="field col-12" *ngIf="canselectEinkauf(selectedAufgabenart)">
        <label for="einkauf"  style="width: 10rem;">Einkauf</label>
        <p-dropdown id="einkauf" [options]="einkaeufe$ | async" [(ngModel)]="selectedEinkauf" optionLabel="name"
          [disabled]="!hasValidEinkauf(selectedEinkauf)" placeholder="Einkauf wählen" (ngModelChange)="changedEinkauf()"
          [style]="{'max-width': '15rem', 'width': '15rem'}">
        </p-dropdown>
      </div>


      <div class="field col-12" *ngIf="canKlammer()">
        <label for="klammer"  style="width: 10rem;">Sortimentsklammer</label>
        <p-dropdown id="klammer" [options]="sortimentsKlammern$ | async" optionLabel="name"
          [(ngModel)]="selectedKlammer" placeholder="Sortimentsklammer wählen"
          (ngModelChange)="filterSortimentByMaster()" [style]="{'max-width': '15rem', 'width': '15rem'}">
        </p-dropdown>
      </div>

      <div class="field col-12" *ngIf="canSortimentsTypen()">
        <label for="typeInfoBox"  style="width: 10rem;">Sortimentstyp</label>
        <p-dropdown id="typeInfoBox" [options]="sortimentsTypen$ | async" optionLabel="name"
          [style]="{'max-width': '15rem', 'width': '15rem'}" showClear="true" placeholder="Filter auswählen"
          [(ngModel)]="selectedType" (ngModelChange)="setSortimentType()">
        </p-dropdown>
      </div>
      <div class="field col-12 " *ngIf="editMode == 'edit' && canSortiment()">
        <label for="selectSortiment"  style="width: 10rem;">Sortiment</label>
        <p-dropdown id="selectSortiment" [options]="sortimentsToView$ | async" optionLabel="name"
          [style]="{'max-width': '15rem', 'width': '15rem'}" placeholder="Sortiment wählen"
          [(ngModel)]="selectedSortiment" filter="true"
          [disabled]="((sortimentsToView$ | async) === null || (selectedKlammer == null))">
        </p-dropdown>
      </div>
      <div class="field col-12 " *ngIf="targetType == 'master' && editMode == 'new' && canSortiment() ">
        <label for="selectSortiments"  style="width: 10rem;">Sortimente</label>
        <p-multiSelect id="chooseSortiment" [options]="sortimentsToView$ | async"
          selectedItemsLabel="{0} Sortimente ausgewählt"
          [disabled]="((sortimentsToView$ | async) === null || (selectedKlammer == null))"
          placeholder="Sortiment wählen" [(ngModel)]="selectedSortimentsForMaster" optionLabel="name"
          [style]="{'max-width': '15rem', 'width': '15rem'}">
        </p-multiSelect>
      </div>
      <div *ngIf="canSelectList()"  class=" grid  col-12 ">

        <div class="field col-3" style="padding-bottom: 0.2rem;">
          <p-checkbox id="withUpload" [(ngModel)]="taskModel.includeUpload" binary="true" label="Liste"></p-checkbox>
        </div>
        <div class="field col-9">
          <p-dropdown id="validationSelection" [options]="validationProfiles$ | async" optionLabel="listName"
            placeholder="Listenart angeben" [(ngModel)]="selectedList" (ngModelChange)="setValidationList()"
            [style]="{'max-width': '18rem', 'width': '18rem'}"></p-dropdown>
        </div>
        <div class="field col-5">
          <label for="minCount" class="col-fixed" style="width: 4rem;">Minimum</label>
          <p-inputNumber id="minCount" [size]=8 [(ngModel)]="taskModel.min" [showButtons]="true"></p-inputNumber>
        </div>
        <div class="field col-2" ></div>
        <div class="field col-5" >
          <label for="maxCount" class="col-fixed" style="width: 4rem;">Maximum</label>
          <p-inputNumber id="maxCount" [size]=8 [(ngModel)]="taskModel.max" [showButtons]="true"></p-inputNumber>
        </div>
        <div class="field col-12" *ngIf="canLoadSnapshot(taskModel)">
          <p-checkbox id="withSnapshot" [(ngModel)]="taskModel.loadSnapshot" binary="true" [disabled]="disableCanSnapshot(taskModel)"
            label="Snapshot"></p-checkbox>
        </div>

        <div class="col-12" *ngIf="canLoadLieferantType(taskModel)">
          <div class="flex flex-wrap gap-3">
            <div class="flex align-items-center">
                <p-radioButton [disabled]="disableCanSnapshot(taskModel)"
                    name="lieferant"
                    value="1"
                    [(ngModel)]="taskModel.lieferantType"
                    inputId="lieferant" />
                <label for="lieferant" class="ml-2">
                    Lieferant
                </label>
            </div>

            <div class="flex align-items-center">
                <p-radioButton [disabled]="disableCanSnapshot(taskModel)"
                    name="cm"
                    value="2"
                    [(ngModel)]="taskModel.lieferantType"
                    inputId="cm" />
                <label for="cm" class="ml-2">
                    Category Captain
                </label>
            </div>


        </div>
        </div>


      </div>
    </div>
  </div>
  <div class="col-7" style="padding-left: 1.25rem !important; border-left: 1px solid lightgray !important;">
    <div class="p-fluid p-formgrid grid">
      <div class="field col-12">
        <label for="taskText">Aufgabentext</label>

        <p-dropdown id="taskText" [options]="taskInfoTexts" [(ngModel)]="taskModel.name"
          placeholder="Wählen Sie einen Aufgabentext" editable="true" [style]="{'width': '100%'}"></p-dropdown>
      </div>
      <div class="field col-12 formAreaSeparatorBottom">
        <label for="taskDescription">Beschreibung</label>
        <textarea style="width: 100%;" id="taskDescription" type="text" [(ngModel)]="taskModel.taskDescription" rows="4"
          cols="80" pInputTextarea autoResize="autoResize"></textarea>
      </div>
      <div class="field col-6 p-md-6">
        <label for="laufzeit" style="width: 4rem;">Laufzeit</label>
        <p-calendar id="laufzeit" [(ngModel)]="taskModel.taskActiveRange" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
          appendTo="body" selectionMode="range" showButtonBar="true" [showIcon]="true"
          (onSelect)="onSelectValidRange($event)" (onInput)="onInputValidRange($event)"></p-calendar>
      </div>
      <div class="field col-6 p-md-6">
        <label for="bearbeitungszeit">Bearbeitung</label>
        <p-calendar id="bearbeitungszeit" [(ngModel)]="taskModel.taskValidRange" [firstDayOfWeek]="1"
          dateFormat="dd.mm.yy" appendTo="body" selectionMode="range" showButtonBar="true"
          [showIcon]="true"></p-calendar>
      </div>
      <div class="field col-6 formAreaSeparatorTop">
        <p-checkbox id="withInvite" [(ngModel)]="taskModel.sendInvite" binary="true" label="Einladen"></p-checkbox>
      </div>
      <div class="field col-6 formAreaSeparatorTop">
        <p-calendar id="einladung" [(ngModel)]="taskModel.invitationDate" [firstDayOfWeek]="1" dateFormat="dd.mm.yy"
          appendTo="body" showButtonBar="true" [showIcon]="true"></p-calendar>
      </div>
      <div class="field col-4" *ngIf="taskModel.sendInvite">
        <p-checkbox id="withInviteOne" [(ngModel)]="taskModel.sendInviteOne" [disabled]="!taskModel.sendInvite"
          binary="true" label="Erinnerung 1"></p-checkbox>
      </div>
      <div class="field col-4" *ngIf="taskModel.sendInvite">
        <p-checkbox id="withInviteTwo" [(ngModel)]="taskModel.sendInviteTwo" [disabled]="!taskModel.sendInviteOne"
          binary="true" label="Erinnerung 2"></p-checkbox>
      </div>
      <div class="field col-4" *ngIf="taskModel.sendInvite">
        <p-checkbox id="withInviteThree" [(ngModel)]="taskModel.sendInviteThree" [disabled]="!taskModel.sendInviteTwo"
          binary="true" label="Erinnerung 3"></p-checkbox>
      </div>
      <div class="field col-4" *ngIf="taskModel.sendInvite">
        <p-calendar id="inviteone" [(ngModel)]="taskModel.dateInviteOne" [disabled]="!taskModel.sendInviteOne"
          [firstDayOfWeek]="1" dateFormat="dd.mm.yy" appendTo="body" showButtonBar="true"
          [showIcon]="true"></p-calendar>
      </div>
      <div class="field col-4" *ngIf="taskModel.sendInvite">
        <p-calendar id="invitetwo" [(ngModel)]="taskModel.dateInviteTwo" [disabled]="!taskModel.sendInviteTwo"
          [firstDayOfWeek]="1" dateFormat="dd.mm.yy" appendTo="body" showButtonBar="true"
          [showIcon]="true"></p-calendar>
      </div>
      <div class="field col-4" *ngIf="taskModel.sendInvite">
        <p-calendar id="invitethree" [(ngModel)]="taskModel.dateInviteThree" [disabled]="!taskModel.sendInviteThree"
          [firstDayOfWeek]="1" dateFormat="dd.mm.yy" appendTo="body" showButtonBar="true"
          [showIcon]="true"></p-calendar>
      </div>
    </div>
  </div>
</div>
<!-- Show Upload-Control only - if Record is new and MasterTask -->
<div class="p-formgrid grid formAreaSeparatorTop" *ngIf="editMode == 'new' && targetType == 'master'">

    <h3>Angehängte Dateien</h3>

  <div class="field col-12">
    <p-fileUpload #fubautoNew name="uploadFiles[]" invalidFileTypeMessageSummary="{0} Datei nicht erlaubt"
      chooseLabel="Neu..." multiple="multiple" fileLimit="5" invalidFileLimitMessageSummary="Maximal 5 Dateien erlaubt"
      accept=".xlsx,.jpg,.png,.pdf" maxFileSize="1000000" customUpload="true" (uploadHandler)="uploadFiles($event)"
      mode="advanced" previewWidth="30" auto="true">
      <ng-template let-file pTemplate="file">
        <div class="p-row row">
          <div style="word-wrap: break-word;" class="col-6">{{ file.name }}</div>
          <div style="word-wrap: break-word;" class="col-4">{{ file.type }}</div>
          <div class="col-2">
            <button pButton type="button" icon="pi pi-trash" class="p-button-secondary"
              (click)="removeFile(file)"></button>
          </div>
        </div>
      </ng-template>
    </p-fileUpload>
  </div>
</div>
<!-- Show List of Uploads and Upload Control when editing, needed for master and supplier tasks -->
<div class="p-formgrid grid" *ngIf="editMode == 'edit'">
  <div class="field col-12 p-md-6">
    <label for="fileTable">Vorhandene Dateien</label>
    <p-table id="fileTable" [value]="getVorhandeneDateien()" scrollHeight="150px" [scrollable]="true">
      <ng-template pTemplate="header">
        <tr>
          <th [ngClass]="'kb-header-cell-default'">Datei</th>
          <th [ngClass]="'kb-header-cell-default'">Typ</th>
          <th [ngClass]="'kb-header-cell-small'"><i class="pi pi-trash"></i></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
        <tr [pSelectableRow]="rowData">
          <td [ngClass]="'kb-cell-default'">{{ rowData.fileName }}</td>
          <td [ngClass]="'kb-cell-default'">{{ rowData.fileType }}</td>
          <td [ngClass]="'kb-cell-small'">
            <button pButton type="button" icon="pi pi-trash" class="p-button-secondary"
              (click)="removeExistingFile(rowData)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="field col-12 p-md-6">
    <label for="newUploads">Weitere Dateien</label>
    <p-fileUpload id="newUploads" #fubauto name="uploadFiles[]" invalidFileTypeMessageSummary="{0} Datei nicht erlaubt"
      chooseLabel="Neu..." multiple="multiple" fileLimit="5" invalidFileLimitMessageSummary="Maximal 5 Dateien erlaubt"
      accept=".xlsx,.jpg,.png,.pdf" maxFileSize="1000000" customUpload="true" (uploadHandler)="uploadFiles($event)"
      mode="advanced" previewWidth="30" auto="true">
      <ng-template let-file pTemplate="file">
        <div class="p-row row">
          <div style="word-wrap: break-word;" class="col-6">{{ file.name }}</div>
          <div style="word-wrap: break-word;" class="col-4">{{ file.type }}</div>
          <div class="col-2">
            <button pButton type="button" icon="pi pi-trash" class="p-button-secondary"
              (click)="removeFile(file)"></button>
          </div>
        </div>

      </ng-template>
    </p-fileUpload>
  </div>
</div>
<div class="grid formAreaSeparatorTop">
  <div class="field col-2">
    <button pButton [disabled]="!canSave()" label="Speichern" class="p-button-info" (click)="saveTask(false)"></button>
  </div>
  <div *ngIf="canSavewithLieferanten()" class="field col-4">
    <button pButton label="Speichern auch für Lieferanten" class="p-button-info"
      (click)="saveTaskwithChildren()"></button>
  </div>
  <div class="field col-2">
    <button pButton label="Beenden" class="p-button-info" (click)="close()"></button>
  </div>
</div>


<p-toast key="successMessage" (onClose)="clearMessages()"></p-toast>
<p-toast key="errorMessage" (onClose)="clearMessages()"></p-toast>


<p-confirmDialog appendTo="body" header="Bitte bestätigen:" icon="pi pi-exclamation-triangle" message="nix"
  rejectLabel="Abbrechen">
</p-confirmDialog>

</div>

