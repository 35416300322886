import { TaskEditorState, InitialTaskEditorState } from './state';
import {
    TaskEditorActions,
    TaskEditorActionType,
    GetValidationsActionDone,
    GetBreadcrumbActionDone,

    GetUsersDoneAction,
    GetCurrentUserDoneAction,
    CreateMasterTaskDoneAction,
    SetUserDataAction,
    ResetTasksDoneAction
} from './actions';

export function reducer(state: TaskEditorState = InitialTaskEditorState, action: TaskEditorActions): TaskEditorState {
    switch (action.type) {
        // case TaskEditorActionType.GetValidationsDone:
        //     return GetValidationsDone(state, action);
        case TaskEditorActionType.GetBreadcrumbDone:
            return GetBreadcrumbDone(state, action);

        case TaskEditorActionType.ClearStore:
            return ClearStore(state);
        case TaskEditorActionType.ResetStore:
            return ResetAttributes(state);
        case TaskEditorActionType.GetUsersDone:
            return UsersDone(state, action);
        case TaskEditorActionType.GetCurrentUserDone:
            return CurrentUserDone(state, action);
        case TaskEditorActionType.CreateMasterTaskDone:
            return CreateMasterTaskDone(state, action);
        case TaskEditorActionType.ResetTasksDone:
            return ResetTasksDone(state, action);
        case TaskEditorActionType.GetBreadcrumb:
            return StartLoading(state);
        
        case TaskEditorActionType.GetUsers:
            return StartUsersLoading(state);
        case TaskEditorActionType.CreateMasterTask:
            return StartTaskSaving(state);

        case TaskEditorActionType.SetUserData:
            return SetSingleUserData(state, action);

    }

    return state;
}

export function GetValidationsDone(state: TaskEditorState, action: GetValidationsActionDone): TaskEditorState {
    console.log('getValidationsDone Reducer');
    console.log('Payload:', action);
    return {
        ...state,
        validationProfiles: action.payload.items
    };
}

export function GetBreadcrumbDone(state: TaskEditorState, action: GetBreadcrumbActionDone): TaskEditorState {
    console.log('GetBreadcrumbDone Reducer');
    console.log('Payload:', action);
    return {
        ...state,
        breadcrumb: action.payload.item,
        loading: false
    };
}


export function ResetAttributes(state: TaskEditorState) {
    return {
        ...state,
        sortiments: [],
        activities: []
    };
}

export function ClearStore(state: TaskEditorState) {
    return {
        ...state,
        activities: [],
        selectableUsers: [],
        validationProfiles: [],
        breadcrumb: '',
        selectedValidationProfile: '',
        currentUser: {},
        loading: false
    };
}



export function UsersDone(state: TaskEditorState, action: GetUsersDoneAction) {
    console.log('UserDone Reducer');
    console.log('Payload:', action);
    return {
        ...state,
        selectableUsers: action.payload.users,
        usersLoading: false
    };
}

export function CurrentUserDone(state: TaskEditorState, action: GetCurrentUserDoneAction) {
    console.log('Current User Done Reducer');
    console.log('Payload: ', action)
    return {
        ...state,
        currentUser: action.payload.user
    };
}

export function CreateMasterTaskDone(state: TaskEditorState, action: CreateMasterTaskDoneAction): TaskEditorState {
    return {
        ...state,
        loading: false,
        lastResult: action.payload.item,
        savingTask: false
    };
}

export function ResetTasksDone(state: TaskEditorState, action: ResetTasksDoneAction) {
    return {
        ...state,
        loading: false,
        savingTask: false
    };
}

export function StartLoading(state: TaskEditorState) {
    return {
        ...state,
        loading: true
    };
}

export function StartUsersLoading(state: TaskEditorState) {
    return {
        ...state,
        usersLoading: true
    };
}

export function StartTaskSaving(state: TaskEditorState) {
    return {
        ...state,
        savingTask: true
    };
}

export function SetSingleUserData(state: TaskEditorState, action: SetUserDataAction) {
    const idx = state.selectableUsers.findIndex(x => x.userId === action.payload.user.userId);
    return {
        ...state,
        selectableUsers: [...state.selectableUsers.slice(0, idx), action.payload.user, ...state.selectableUsers.slice(idx + 1)]
    };
}
