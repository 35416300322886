import { AnyARecord } from 'dns';
import { ValidResult } from './validResult.model';

export enum DynListOption
{
  nicht_ausgewählt = 0,
  MöbeldesSortimentsundModul = 1,
}

export enum ColumnTypes {
    Text = 0,
    Number = 1,
    CheckBox = 2,
    List = 3,
    NumberRange = 4,
    DateRange = 5,
    Date = 6,
    ExternalValidation = 7,
    ///Liste die zur Laufzeit generiert wird
    DynamicList = 8,
}

export interface ColumnBase {
    id: number;
    colName: string;
    colDesc: string;
    colType: ColumnTypes;
    dbMapping: string;
    bubMapping: string;
    createdAt: Date;
    changedAt: Date;
    validationRule: string;
}

export interface ListValue {
    valuelist: { item: string }[];
}


export interface DynListValue {
  label: string;
  value: DynListOption;
}

export interface NumberRangeValue {
    numberrangestart: number;
    numberrangeend: number;
}

export interface DateRangeValue {
    daterangestart: Date;
    daterangeend: Date;
}

export interface DateValue {
    value: Date;
}

export interface BoolValue {
    value: boolean;
}

export interface TextValue {
    maxlength: number;
    validregex: string;
}

export interface NumberValue {
    maxdigits: number;
    maxdecimals: number;
    isnumberonly: boolean;
}
