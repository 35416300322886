import { AppState as RootState } from '../../store';
import { UploadRowModel, RowContentModel, UploadDetailsModel, UploadStagingModel, ValidationList, ResultObjectModel, ValidationListColumn } from '@app/common/models';
import { createFeatureSelector } from '@ngrx/store';


export interface ListEditorState {
    uploadName: string;
    uploadStaging: UploadStagingModel;
    rowList: UploadRowModel[];
    rowContentList: RowContentModel[];
    uploadId: number;
    validationProfiles: ValidationList[];
    errorResult:  ResultObjectModel<any>;
    validationsbyUsedColumnId: { [key: number]: ValidationListColumn };

}

export interface AppState extends RootState {
    listeditorview: ListEditorState;
}

export const InitialListEditorState: ListEditorState = {
    rowList: [],
    uploadStaging: {} as UploadStagingModel,
    uploadName: 'NN',
    uploadId: -1,
    rowContentList: [],
    validationProfiles: [],
    errorResult: undefined,
    validationsbyUsedColumnId:[]

}

export const featureName = 'listeditorview';

export const selectListEditorState = createFeatureSelector<ListEditorState>(featureName);
