

import { SimpleResultError } from '@app/common/models/simpleResult.model';

import { CustomSetting } from '@app/common/models/custom.settings.models';

import { LookupValue } from '@app/common/models/novi/columnLookup.model';
import * as cloneDeep from 'lodash/fp/cloneDeep';



import { EinkaufDto, KlammerSortimentStichwort, ListResponseArgsOfKlammerSortimentStichwort, SortimentPlatzierungDto } from '@app/api/filialmatrix/models';
import { StichworthintEnum } from '../../common/models/PlatzierungsClientDto';
import { SortimentsstrukturService } from '@app/api/filialmatrix/services';

import { CommonDataError, CommonDataState, InitialCommonDataState, KeyordEbene, KeywordError } from './state';
import { CommonDataActionType, CommonDataErrorAction, DataCommonActions } from './actions';

import { DataColumnConfigSuccess as DataColumnConfigSuccess, DataColumnConfigSuccessEnum as DataColumnConfigSuccessEnum, StoreColumnSetting } from '../models/storecolumnsetting';
import { WarengroupDto } from '@app/common/models/warengroupdto';
import { CompareTitleFis } from '@app/common/models/novi/compareTitel.model';
import { GeneralResultEnum } from '@app/common/models';
import { SortimentDtoClient } from '@app/common/models/sortimente/SortimentDtoClient';
import { KlammerDtoClient } from '@app/common/models/sortimente/KlammerDtoClient';
import { TypDtoClient } from '@app/common/models/sortimente/SortimentstypenDtoClient';
import { KeyValueDtoClient } from '@app/common/models/sortimente/KeyValueDtoClient';
import { EinkaufDtoClient } from '@app/common/models/novi/einkauf.dto.client';
import { MandantDtoClient } from '@app/common/models/mandant/mandant.dto.client';
import { LieferantDtoClient } from '@app/common/models/lieferant/lieferant.dto.client';




/**zum Tree umbauen */
function toTree(result: KlammerSortimentStichwort[]): any {

  var treeresult = new Array<KlammerSortimentStichwort>();
  var distinctklammern = [];
  result.forEach(t => {
    if (distinctklammern.find(f => f.klammerId == t.klammerId) == undefined) {
      distinctklammern.push(<KlammerSortimentStichwort>{ klammerId: t.klammerId, klammerName: t.klammerName });
    }
  })
  distinctklammern.forEach(kl => {
    var klammersortimente = result.filter(f => f.klammerId == kl.klammerId);
    var distinctsortimente = [];
    klammersortimente.forEach(so => {
      if (distinctsortimente.find(f => f.sortimentId == so.sortimentId) == undefined) {

        var klammersortimentestichworte = result.filter(f => f.klammerId == kl.klammerId && f.sortimentId == so.sortimentId);
        var distinctstichworte = [];
        klammersortimentestichworte.forEach(st => {
          if (distinctstichworte.find(f => f.sortimentId == so.sortimentId) == undefined) {
            distinctstichworte.push(<KlammerSortimentStichwort>{
              klammerId: kl.klammerId, klammerName: kl.klammerName,
              sortimentId: so.sortimentId, sortimentName: so.sortimentName,
              stichwortId: st.stichwortId, stichwortName: st.sortimentName

            });
          }
        })

        distinctsortimente.push(<KlammerSortimentStichwort>{
          klammerId: kl.klammerId, klammerName: kl.klammerName,
          sortimentId: so.sortimentId, sortimentName: so.sortimentName, children: distinctstichworte
        });
      }
    })

    treeresult.push(<KlammerSortimentStichwort>{
      klammerId: kl.klammerId, klammerName: kl.klammerName,
      children: distinctsortimente
    });


  })

  return treeresult;

}




export function reducer(state: CommonDataState = InitialCommonDataState, action: DataCommonActions): CommonDataState {

  switch (action.type) {





    case CommonDataActionType.GetBranchGroupsActionDone:

      return {
        ...state,
        allBranchGroups: action.payload.items
      }


    case CommonDataActionType.GetBranchesActionDone:

      return {
        ...state,
        allBranches: action.payload.items
      }


    case CommonDataActionType.DeleteColumnConfigDone:
    case CommonDataActionType.DeleteCustomConfigbyNameDone:

      var configsalldel = [...state.selectedColumnConfigs];
      configsalldel = configsalldel.filter(f => f.id != action.payload.id);
      return {
        ...state,
        configSuccess: <DataColumnConfigSuccess>{ data: action.payload.id, type: DataColumnConfigSuccessEnum.gelöscht },
        selectedColumnConfigs: configsalldel
      };



    case CommonDataActionType.GetLieferantAction:
      return {
        ...state,
      }

    case CommonDataActionType.GetLieferantActionDone:

      return {
        ...state,
        lieferant: action.payload.result && action.payload.result.length == 1 ? new LieferantDtoClient(action.payload.result[0]) : undefined
      }


    case CommonDataActionType.GetLieferantenAction:

      return {
        ...state,
        isLoadinglieferanten: true,
      }

    case CommonDataActionType.GetLieferantenActionDone:

      return {
        ...state,
        isLoadinglieferanten: false,
        lieferanten: action.payload.result.map(m => new LieferantDtoClient(m))
      }

    case CommonDataActionType.GetMandantAction:
      return {
        ...state,
        isLoadingMandanten: true,
      }

    case CommonDataActionType.GetMandantActionDone:
      return {
        ...state,
        isLoadingMandanten: false,
        mandant: action.payload.result[0] ? new MandantDtoClient(action.payload.result[0]) : undefined
      }

    case CommonDataActionType.GetLagerActionDone:
      return {
        ...state,

        allLager: action.payload.lager,
      };

    case CommonDataActionType.GetMandantenActionDone:
      return {
        ...state,
        mandanten: action.payload.result.map(m => new MandantDtoClient(m))
      }

    case CommonDataActionType.GetDashboardSumActionDone:
      return {
        ...state,
        dashboardSum: action.payload.result.result
      }

    case CommonDataActionType.GetNoviHitsActionDone:
      return {
        ...state,
        novitaetenHits: action.payload.result.result
      }

    case CommonDataActionType.GetNovitaetenListenActionDone:
      var stateobj = cloneDeep(state.listendtos);
      stateobj[action.payload.typ] = action.payload.list.result;
      return {
        ...state,
        listendtos: stateobj
      }


    case CommonDataActionType.GetCompareListForActionDone:
      let dtos: CompareTitleFis[] = [];
      let compareList = cloneDeep(state.compareTitles);
      try {

        dtos = cloneDeep(action.payload.compareTitles);
        dtos.forEach(dto => {
          dto.meta.group = dto.meta.group ?? "HUG";
          dto.noviId = action.payload.noviwupId.toString();
        })
        compareList[action.payload.noviwupId] = dtos;
      } catch (e) {
        return {
          ...state,
          commondataError: <CommonDataError>{ type: action.type, error: <SimpleResultError>{ userMessage: "Fehler Vergleichstitel laden", generalResult: GeneralResultEnum.OnlyDebug, serverMessage: e.toString() } },
        }
      }


      return {
        ...state,
        compareTitles: compareList
      }

    case CommonDataActionType.RemovePrepareUpdateorAddColorofUser:
      return {
        ...state,
        preparedusersColors: [...state.preparedusersColors.filter(f => f.purchaseItem_Id != action.payload.id.toString())],
      };


    case CommonDataActionType.ClearPrepareUpdateorAddColorofUser:
      return {
        ...state,
        preparedusersColors: [],
      };


    case CommonDataActionType.PrepareUpdateorAddColorofUser:
      return {
        ...state,
        preparedusersColors: [...state.preparedusersColors.filter(f => f.purchaseItem_Id != action.payload.colorofuser.purchaseItem_Id), { ...action.payload.colorofuser }],
        configSuccess: <DataColumnConfigSuccess>{ data: action.payload.colorofuser, type: DataColumnConfigSuccessEnum.gespeichert }
      };

    case CommonDataActionType.UpdateorAddColorofUserDone:
      return {
        ...state,
        preparedusersColors: [],
        usersColors: [...state.usersColors.filter(f => f.purchaseItem_Id != action.payload.colorofuser.purchaseItem_Id), { ...action.payload.colorofuser }],
        configSuccess: <DataColumnConfigSuccess>{ data: action.payload.colorofuser, type: DataColumnConfigSuccessEnum.gespeichert }
      };

    case CommonDataActionType.GetNovitaetBubDataByEansAction:

      return {
        ...state,
        isLoadingBubData: true,
        failedbubDataList: []
      };

    case CommonDataActionType.GetNovitaetBubDataByEansActionDone:

      var failed = action.payload.searchedeans.filter(f => action.payload.bubDataList.find(s => s.ean == f) == undefined);
      return {
        ...state,
        isLoadingBubData: false,
        failedbubDataList: failed,
        bubDataList: [...state.bubDataList.filter(f => action.payload.bubDataList.filter(e => e.ean == f.ean).length == 0), ...action.payload.bubDataList]
      };


    case CommonDataActionType.GetNovitaetBubDataByEanAction:
      return {
        ...state,
        isLoadingBubData: true,
        failedbubDataList: []
      };

    case CommonDataActionType.GetNovitaetBubDataByEanActionDone:
      if (action.payload.bubData) {
        return {
          ...state,
          isLoadingBubData: false,
          bubDataList: [...state.bubDataList.filter(f => action.payload.bubData.ean != f.ean), { ...action.payload.bubData }]
        };
      } else {
        return {
          ...state,
          isLoadingBubData: false,
          failedbubDataList: [action.payload.searchedean]
        };
      }

    case CommonDataActionType.GetDistinctColorsofUserDone:
      var colors = action.payload.colorsofUser.map(f => f.color);
      var distinctcolors = colors.filter((n, i) => colors.indexOf(n) === i);
      return {
        ...state,
        usersColors: action.payload.colorsofUser,
        distinctusersColors: distinctcolors,
        configSuccess: undefined,
      };




    case CommonDataActionType.GetStichwortBaumbyKlammerExpandDone:
      var baum = cloneDeep(state.stichwortstructureList);
      baum[action.payload.id] = action.payload.data.result;
      var currenterr = { ...state.keywordErrors };
      delete currenterr[action.payload.id];

      var loaded = cloneDeep(state.stichwortstructureisLoaded);
      loaded[action.payload.id] = KeyordEbene.Klammer;

      return {
        ...state,

        stichwortstructureList: baum,
        keywordErrors: currenterr,
        stichwortstructureisLoaded: loaded,

      };

    /**Cache leeren für bestimmte Themen */
    case CommonDataActionType.ClearCommonDataCacheAction:
      return {
        ...state,
        stichwortstructureList: action.payload.clearstichwortList ? {} : state.stichwortstructureList,
        keywordErrors: action.payload.clearstichwortList ? {} : state.keywordErrors,
        stichwortstructureisLoaded: action.payload.clearstichwortList ? {} : state.stichwortstructureisLoaded,
        stichwortstructureListAll: action.payload.clearstichwortList ? [] : state.stichwortstructureListAll,
        allSortimente: action.payload.clearsortimentStruktur ? [] : state.allSortimente,
        allKategorien: action.payload.clearsortimentStruktur ? [] : state.allKategorien,
        allKlammern: action.payload.clearsortimentStruktur ? [] : state.allKlammern,
        allsortimentsTypen: action.payload.clearsortimentStruktur ? [] : state.allsortimentsTypen,
        bedarfe: action.payload.clearsortimentStruktur ? {} : state.bedarfe,
      };




      break;

    case CommonDataActionType.GetStichwortBaumbyKlammerSortimentExpandDone:
      var baum = cloneDeep(state.stichwortstructureList);
      baum[action.payload.id] = action.payload.data.result;
      var currenterr = { ...state.keywordErrors };
      delete currenterr[action.payload.id];

      var loaded = cloneDeep(state.stichwortstructureisLoaded);
      loaded[action.payload.id] = KeyordEbene.Sortiment;

      return {
        ...state,

        stichwortstructureList: baum,
        keywordErrors: currenterr,
        stichwortstructureisLoaded: loaded,

      };


    case CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortExpand:

      return {
        ...state,
      };

    case CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortExpandDone:
      var baum = cloneDeep(state.stichwortstructureList);

      baum[action.payload.id] = action.payload.data.result;

      var currenterr = { ...state.keywordErrors };
      delete currenterr[action.payload.id];

      var loaded = cloneDeep(state.stichwortstructureisLoaded);
      loaded[action.payload.id] = KeyordEbene.Stichwort;


      return {
        ...state,

        stichwortstructureList: baum,
        keywordErrors: currenterr,
        stichwortstructureisLoaded: loaded,

      };



    case CommonDataActionType.GetStichwortBaumbyKlammerSortimentDone:

      var baum = cloneDeep(state.stichwortstructureList);
      var parttree = baum[action.payload.id];

      if (parttree == undefined) {
        baum[action.payload.id] = toTree(action.payload.data.result);
      } else {
        var thisklammerid = parttree.findIndex(f => f.klammerId == action.payload.params.klammerId);
        if (thisklammerid > -1) {
          var thissortimentid = parttree[thisklammerid].children ? parttree[thisklammerid].children.findIndex(f => f.sortimentId == action.payload.params.sortimentId) : -1;
          if (thissortimentid > -1) {
            // Stichwörte leeren und neu befüllen
            parttree[thisklammerid].children[thissortimentid].children = [];
            action.payload.data.result.forEach(c => {
              parttree[thisklammerid].children[thissortimentid].children.push(<KlammerSortimentStichwort>{
                klammerId: parttree[thisklammerid].klammerId,
                klammerName: parttree[thisklammerid].klammerName,
                sortimentId: parttree[thisklammerid].children[thissortimentid].sortimentId, sortimentName: parttree[thisklammerid].children[thissortimentid].sortimentName,
                stichwortId: c.stichwortId, stichwortName: c.stichwortName
              })
            })
            if (action.payload.data.result.length == 0) {
              parttree[thisklammerid].children[thissortimentid].children.push(<KlammerSortimentStichwort>{
                klammerId: parttree[thisklammerid].klammerId,
                klammerName: parttree[thisklammerid].klammerName,
                sortimentId: parttree[thisklammerid].children[thissortimentid].sortimentId, sortimentName: parttree[thisklammerid].children[thissortimentid].sortimentName,
                stichwortId: undefined, stichwortName: StichworthintEnum.keine_weiteren_Stichwörter
              })
            }

          }
        }
      }
      var currenterr = { ...state.keywordErrors };
      delete currenterr[action.payload.id];
      return {
        ...state,

        stichwortstructureList: baum,
        keywordErrors: currenterr
      };

    case CommonDataActionType.GetStichwortBaumbyKlammerSortimentStichwortDone:

      var baum = cloneDeep(state.stichwortstructureList);


      var parttree = baum[action.payload.id];
      if (parttree == undefined) {
        baum[action.payload.id] = toTree(action.payload.data.result);
      } else {
        var thisklammerid = parttree.findIndex(f => f.klammerId == action.payload.params.klammerId);
        if (thisklammerid > -1) {
          var thissortimentid = parttree[thisklammerid].children ? parttree[thisklammerid].children.findIndex(f => f.sortimentId == action.payload.params.sortimentId) : -1;
          if (thissortimentid > -1) {

            if (parttree[thisklammerid].children[thissortimentid].children != undefined && parttree[thisklammerid].children[thissortimentid].children.length > 0) {
              var thisstichwortid = parttree[thisklammerid].children[thissortimentid].children.findIndex(f => f.stichwortId == action.payload.params.stichwortId);
              if (thisstichwortid > -1) {
                // Stichwörte anhängen
                parttree[thisklammerid].children[thissortimentid].children[thisstichwortid].children = [];
                action.payload.data.result.forEach(c => {
                  parttree[thisklammerid].children[thissortimentid].children[thisstichwortid].children.push(<KlammerSortimentStichwort>{
                    klammerId: parttree[thisklammerid].klammerId,
                    klammerName: parttree[thisklammerid].klammerName,
                    sortimentId: parttree[thisklammerid].children[thissortimentid].sortimentId, sortimentName: parttree[thisklammerid].children[thissortimentid].sortimentName,
                    stichwortId: c.stichwortId, stichwortName: c.stichwortName
                  })
                })
              } else if (parttree[thisklammerid].children[thissortimentid].children) {

                // weitersuchen wenn children
                fillSubTree(parttree[thisklammerid].children[thissortimentid].children, action.payload.params, action.payload.data)


              }
            } else {
              parttree[thisklammerid].children[thissortimentid].children = [];
              // Stichwörte leeren und neu befüllen ohne weiteren Zweig ?!
              action.payload.data.result.forEach(c => {
                parttree[thisklammerid].children[thissortimentid].children.push(<KlammerSortimentStichwort>{
                  klammerId: parttree[thisklammerid].klammerId,
                  klammerName: parttree[thisklammerid].klammerName,
                  sortimentId: parttree[thisklammerid].children[thissortimentid].sortimentId, sortimentName: parttree[thisklammerid].children[thissortimentid].sortimentName,
                  stichwortId: c.stichwortId, stichwortName: c.stichwortName
                })
              })
            }
          }
        }
      }

      var currenterr = { ...state.keywordErrors };
      delete currenterr[action.payload.id];
      return {
        ...state,
        stichwortstructureList: baum,
        keywordErrors: currenterr

      };


    /**gecachten Baum übertragen auf node Platzierung */
    case CommonDataActionType.AddGetStichwortBaum:
      var baum = { ...state.stichwortstructureList };
      baum[action.payload.id] = action.payload.data;
      return {
        ...state,
        stichwortstructureList: baum
      };


    case CommonDataActionType.GetStichwortBaumDone:
      var baum = { ...state.stichwortstructureList };
      baum[action.payload.id] = action.payload.data.result;
      var currenterr = { ...state.keywordErrors };
      delete currenterr[action.payload.id];

      var loaded = cloneDeep(state.stichwortstructureisLoaded);
      loaded[action.payload.id] = KeyordEbene.All;

      return {
        ...state,
        stichwortstructureListAll: action.payload.data.result,
        stichwortstructureList: baum,
        keywordErrors: currenterr,
        stichwortstructureisLoaded: loaded

      };



    case CommonDataActionType.GetStichwortBaumbyKlammerDone:


      var baum = cloneDeep(state.stichwortstructureList);
      var parttree = baum[action.payload.id];
      if (parttree == undefined) {
        baum[action.payload.id] = toTree(action.payload.data.result);
      } else {
        var thisklammerid = parttree.findIndex(f => f.klammerId == action.payload.klammerid);
        if (thisklammerid > -1) {
          parttree[thisklammerid].children = [];
          action.payload.data.result.forEach(c => {

            parttree[thisklammerid].children.push(<KlammerSortimentStichwort>{
              klammerId: parttree[thisklammerid].klammerId,
              klammerName: parttree[thisklammerid].klammerName,
              sortimentId: c.sortimentId, sortimentName: c.sortimentName
            })
          })
        }
      }
      var currenterr = { ...state.keywordErrors };
      delete currenterr[action.payload.id];
      return {
        ...state,

        stichwortstructureList: baum,
        keywordErrors: currenterr

      };

    case CommonDataActionType.GetVerlagebySearchAction:
      return {
        ...state,
        isLoadingVerlage: true
      };

    case CommonDataActionType.GetVerlagebySearchActionDone:


      return {
        ...state,
        searchedVerlage: action.payload.result ?? [],
        isLoadingVerlage: false
      };


    case CommonDataActionType.GetVerlagChildrenActionDone:

      let objverlage = { ...state.verlagewithChildren };
      objverlage[action.payload.id] = action.payload.result;

      return {
        ...state,
        verlagewithChildren: objverlage
      };



    case CommonDataActionType.GetNovitaetenWarengruppenActionDone:
      return {
        ...state,
        allWarenGroup: action.payload.list.map(f => <WarengroupDto>{ display: f.value + " (" + f.display + ")", value: f.value })
      };


    case CommonDataActionType.GetNovitaetenKategorienActionDone:
      return {
        ...state,
        allKategorien: [...action.payload.list].sort((a, b) => { return a.sort != undefined && b.sort != undefined && a.sort < b.sort ? -1 : 1 })
      };


    case CommonDataActionType.GetKlammernActionDone:
      return {
        ...state,
        allKlammern: action.payload.klammern.result.map(k => new KlammerDtoClient(k))
      };

    case CommonDataActionType.GetSortimentstypenActionDone:
      return {
        ...state,
        allsortimentsTypen: action.payload.typen.map(s => new TypDtoClient(s))
      };

    case CommonDataActionType.GetSortimenteActionDone:
      return {
        ...state,
        allSortimente: action.payload.sortimente.result.map(s => new SortimentDtoClient(s))
      };

    case CommonDataActionType.GetNovitaetenSoMaContactsActionDone:
      return {
        ...state,
        lipoContacts: action.payload.list,
      };

    case CommonDataActionType.GetRegionenActionDone:
      return {
        ...state,
        allRegionen: action.payload.list.result
      };


    case CommonDataActionType.GetSaisonsActionDone:

      return {
        ...state,
        allSaisons: action.payload.list.result
      };

    case CommonDataActionType.GetMarketingEreignisseActionDone:

      return {
        ...state,
        allEreignisse: action.payload.list.result
      };


    case CommonDataActionType.GetEinkaeufeActionDone:

      return {
        ...state,
        allEinkaeufe: action.payload.list.result.map(f => new EinkaufDtoClient(f))
      };


    case CommonDataActionType.GetNovitaetenLookupsIntActionDone:
      let changestateint = { ...state.idNameList };
      changestateint[action.payload.key] = action.payload.list.result.map(f => <LookupValue>{ id: f, value: f?.toString(), valueName: f?.toString(), name: f?.toString() });
      return {
        ...state,
        idNameList: changestateint
      };




    case CommonDataActionType.GetNovitaetenLookupsStringActionDone:
      let changestate1 = { ...state.idNameList };
      changestate1[action.payload.key] = action.payload.list.result.map(f => <LookupValue>{ id: -1, value: f, valueName: f, name: f });
      return {
        ...state,
        idNameList: changestate1
      };


    case CommonDataActionType.GetNovitaetenLookupsIdNameActionDone:
      let changestate = { ...state.idNameList };
      changestate[action.payload.key] = action.payload.list.result.map(f => <LookupValue>{ id: Number(f['key']), value: f['key'].toString(), name: f['value'], valueName: f['value'] });;
      return {
        ...state,
        idNameList: changestate
      };



    case CommonDataActionType.UpdateLastAccessConfigDone:
      //Update aller lastaccess Properties
      const idxupdate = state.selectedColumnConfigs.findIndex(x => x.id == action.payload.id);
      if (idxupdate > -1) {
        var r = state.selectedColumnConfigs[idxupdate];
        var changed = <CustomSetting<StoreColumnSetting>>{ id: r.id, name: r.name, modul: r.modul, type: r.type, objectType: r.objectType, userId: r.userId, settingValue: r.settingValue, lastAccess: 0, data: r.data };
        var first = state.selectedColumnConfigs.slice(0, idxupdate);
        var newfirst = first.map(r => { return <CustomSetting<StoreColumnSetting>>{ id: r.id, name: r.name, modul: r.modul, type: r.type, objectType: r.objectType, userId: r.userId, settingValue: r.settingValue, lastAccess: 0, data: r.data }; });
        var last = state.selectedColumnConfigs.slice(idxupdate + 1);
        var newlast = last.map(r => { return <CustomSetting<StoreColumnSetting>>{ id: r.id, name: r.name, modul: r.modul, type: r.type, objectType: r.objectType, userId: r.userId, settingValue: r.settingValue, lastAccess: 0, data: r.data }; });
        var newsettings = [...newfirst, { ...changed }, ...newlast].sort((a, b) => a.lastAccess - b.lastAccess);
        return {
          ...state,
          selectedColumnConfigs: newsettings
        };
      }
      return state;

    case CommonDataActionType.CommonDataErrorAction:
      return getErrorAction(state, action);

    case CommonDataActionType.DeleteColumnConfigDone:
      const idx0 = state.selectedColumnConfigs.findIndex(x => x.id == action.payload.id);
      var newsettings1 = [...state.selectedColumnConfigs.slice(0, idx0), ...state.selectedColumnConfigs.slice(idx0 + 1)].sort((a, b) => a.lastAccess - b.lastAccess);
      return {
        ...state,

        selectedColumnConfigs: newsettings1,
        configSuccess: <DataColumnConfigSuccess>{ data: action.payload.id, type: DataColumnConfigSuccessEnum.gelöscht }
      };


    case CommonDataActionType.GetZukuenftigeEinkaufAction:
      return {
        ...state,
        zukuenftigeEinkaufisLoading: true,
      };

    case CommonDataActionType.GetZukuenftigeEinkaufActionDone:

      return {
        ...state,
        zukuenftigeEinkaeufe: action.payload.list.result.map(f => new EinkaufDtoClient(f)),
        zukuenftigeEinkaufisLoading: false,
      };

    case CommonDataActionType.GetAktuellenEinkaufAction:
      return {
        ...state,
        aktuellerEinkaufisLoading: true,
      };

    case CommonDataActionType.GetAktuellenEinkaufActionDone:

      return {
        ...state,
        aktuellerEinkauf: action.payload.list.result[0],
        aktuellerEinkaufisLoading: false,
      };

    case CommonDataActionType.UpdateColumnConfigDone:
      const idx = state.selectedColumnConfigs.findIndex(x => x.id == action.payload.customsetting.id);

      var first = state.selectedColumnConfigs.slice(0, idx);
      var last = state.selectedColumnConfigs.slice(idx + 1);
      var newsettings11 = [...first, { ...action.payload.customsetting }, ...last].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

      return {
        ...state,

        configSuccess: <DataColumnConfigSuccess>{ data: action.payload.customsetting, type: DataColumnConfigSuccessEnum.gespeichert },
        selectedColumnConfigs: newsettings11
      };


    case CommonDataActionType.InsertColumnConfigDone:
      var all = [...state.selectedColumnConfigs];
      return {
        ...state,

        configSuccess: <DataColumnConfigSuccess>{ data: action.payload.customsetting, type: DataColumnConfigSuccessEnum.erstellt },
        selectedColumnConfigs: [action.payload.customsetting, ...all]
      };

    case CommonDataActionType.ClearConfigSuccess:
      return {
        ...state,
        configSuccess: undefined
      };

    case CommonDataActionType.GetColumnConfigsDone:
      var payload = [...action.payload.columnConfigs];
      payload = payload.sort((a, b) => {
        if (!a.name || !b.name) return -1;
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      })
      return {
        ...state,
        selectedColumnConfigs: payload
      };


    case CommonDataActionType.KeywordErrorAction:

      let obj = { ...state.keywordErrors };
      obj[action.payload.id] = action.payload.message ? <KeywordError>{ id: action.payload.id, simpleresult: <SimpleResultError>{ message: action.payload.message } } : undefined;
      return {
        ...state,
        keywordErrors: obj

      };


    case CommonDataActionType.ClearCommonDataErrorAction:
      return {
        ...state,
        commondataError: <CommonDataError>{},
        keywordErrors: {},
        isLoadingBubData: false,
        failedbubDataList: []
      };


    case CommonDataActionType.GetKategorienderVorabplatzierungenDone:

      var dict = { ...state.katgegorienausVorabplatzierungen };
      dict[action.payload.key] = action.payload.result;
      return {
        ...state,
        katgegorienausVorabplatzierungen: dict
      };


      case CommonDataActionType.GetMoebelAction:
        return {
          ...state,
          isMoebelLoading: true
        };

      case CommonDataActionType.GetMoebelActionDone:
        var newmoebel = { ...state.moebel };
        if (newmoebel == undefined) { newmoebel = {}; }
        newmoebel[action.payload.params.id] = <any>action.payload.moebel;
        return {
          ...state,
          isMoebelLoading: false,
          moebel: newmoebel
        };

    case CommonDataActionType.GetSettingsChildrenActionDone:
      var obj0 = { ...state.settingChildrenValues } as any;
      obj0[action.payload.settingvalueId] = action.payload.settingChildrenValues;
      return {
        ...state,
        settingChildrenValues: obj0,
        isLoading: false
      };

    case CommonDataActionType.GetSettingsDone:
      return {
        ...state,
        settingValues: action.payload.settingValues,
      };


  }
  return state;
}

/**Requesterrors */
export function getErrorAction(state: CommonDataState, action: CommonDataErrorAction): CommonDataState {
  var errmessage = SimpleResultError.getSimpleResultError(action.payload.error, action.payload.message);
  return {
    ...state,
    isLoading: false,
    isLoadingVerlage: false,
    isLoadingBubData: false,
    commondataError: <CommonDataError>{ type: action.payload.type, message: action.payload.message, error: errmessage }
  };
}



/**Stichwort subtree weiter füllen */
function fillSubTree(parttree: KlammerSortimentStichwort[],
  params: SortimentsstrukturService.SortimentsstrukturGetKlammerSortimentStichwort4Params, data: ListResponseArgsOfKlammerSortimentStichwort) {
  if (parttree == undefined) return;

  var thisstichwortid = parttree.findIndex(f => f.stichwortId == params.stichwortId);
  if (thisstichwortid > -1 && parttree[thisstichwortid].children == undefined) {
    // Stichwörte anhängen
    parttree[thisstichwortid].children = [];
    data.result.forEach(c => {
      parttree[thisstichwortid].children.push(<KlammerSortimentStichwort>{
        klammerId: parttree[thisstichwortid].klammerId,
        klammerName: parttree[thisstichwortid].klammerName,
        sortimentId: parttree[thisstichwortid].sortimentId, sortimentName: parttree[thisstichwortid].sortimentName,
        stichwortId: c.stichwortId, stichwortName: c.stichwortName
      })
    })
  } else if (parttree.length > 0) {
    parttree.forEach(node => {
      if (node.children) {
        fillSubTree(node.children, params, data);
      }
    })
  }
}

