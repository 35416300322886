import { OnInit, Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AuthService } from '@app/common/services/auth.service';
import {
  GetAllAvailableColumnsAction,
  selectAllAvailableColumns,
  selectAllValidationLists,
  GetAllValidationListsAction,
  GetAllValidationColumnsAction,
  selectValidationEditorState,
  selectValidationColumnsForList,
  MoveAvailableToListAction,
  SaveValidationListAction,
  AddNewValidationListAction,
  AddAvailableColumnAction,
  SaveAvailableColumnAction,
  AddValidationColumnDoneAction,
  SaveValidationColumnDoneAction,
  AddValidationColumnAction,
  SaveValidationColumnAction,
  CreateValidationColFromAvailableDoneAction,
  CreateValidationColFromAvailableAction,
  DeleteAvailableColumnAction,
  DeleteValidationListColumnAction,
  DeleteValidationListDoneAction,
  DeleteValidationListAction,
  SaveValidationColumnsSortOrderAction
} from './store';
import { ValidationList, ValidationListColumn, AvailableColumn } from '@app/common/models';
import { map, filter } from 'rxjs/operators';
import { Observable, empty, EMPTY, of } from 'rxjs';
import { ColumnTypes } from '@app/common/models/columnBase.model';
import { EditValidationColumnDialogComponent } from './pages/editValidationColumn.dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { MessageService, ConfirmationService, MenuItem } from 'primeng/api';
import * as lodash from 'lodash';
import { EditValidationListDialogComponent } from './pages/editValidationList.dialog.component';
import { EditAvailableColumnDialogComponent } from './pages/editAvailableColumn.dialog.component';
import { ColumnOrderModel } from '@app/common/models/columnOrder.model';
import { environment } from '@env/environment';
import { DeleteCustomConfigbyName, GetSettingsStateAction } from '@app/common-data-module/store';
import { CustomSettingsModulEnum, CustomSettingsTypeEnum } from '@app/common/models/custom.settings.models';
import { SettingValueEnum } from '@app/common/models/setting.models';

@Component({
  selector: 'app-validation-editor',
  templateUrl: 'validation-editor.component.html',
  styleUrls: ['./validation-editor.component.scss'],
})

export class ValidationEditorComponent implements OnInit {

  // @ts-ignore
  availableColumns$ = this.store.pipe(select(selectAllAvailableColumns));
  // @ts-ignore
  validationLists$ = this.store.pipe(select(selectAllValidationLists));
  // @ts-ignore
  validationListColumns$ = this.store.pipe(select(selectValidationColumnsForList));
  columnsForSelectedList$: Observable<ValidationListColumn[]> = of([]);

  selectedList: ValidationList;
  selectedAvailableColumn: AvailableColumn;
  // @ts-ignore
  isAdminUser$ = this.authService.claims$
    .pipe(
      filter(f => !!f.member_of),
      map(userInfo => environment.admins.some(x => userInfo.member_of.includes(x))));
  isAdmin: boolean;

  constructor(private store: Store<any>, private authService: AuthService, private confirmationService: ConfirmationService,
    private dialogService: DialogService) {
    const sub = this.isAdminUser$.subscribe(x => { this.isAdmin = x; });
    sub.unsubscribe();


  }

  ngOnInit() {
    this.load();
    this.store.dispatch(new GetSettingsStateAction( { settingid:  SettingValueEnum.DBMapping, onlyActive: true  }));
  }

  load() {
    this.store.dispatch(new GetAllAvailableColumnsAction());
    this.store.dispatch(new GetAllValidationListsAction());
    this.store.dispatch(new GetAllValidationColumnsAction());
  }

  listSelected() {
    this.columnsForSelectedList$ = this.validationListColumns$.pipe(map(x => x && x.filter(y => y && y.validationListId == this.selectedList.id).sort((a, b) => a.sortOrder - b.sortOrder)));
  }

  listDeselected() {
    this.columnsForSelectedList$ = EMPTY;
  }

  addAvailableToList() {
    if (!this.selectedList) {
      this.showMessage('Spalten übernehmen', 'Bitte wählen Sie erst eine Liste aus');
      return;
    }
    if (!this.selectedAvailableColumn) {
      this.showMessage('Spalten übernehmen', 'Bitte wählen Sie eine oder mehrere verfügbare Spalten aus');
      return;
    }
    this.store.dispatch(new CreateValidationColFromAvailableAction({ availableColumnId: this.selectedAvailableColumn.id, validationListId: this.selectedList.id }));
  }

  editValidationColumn(rowData: ValidationListColumn) {
    if (!this.selectedList) {
      this.showMessage('Spalten übernehmen', 'Bitte wählen erst ein Liste aus');
      return;
    }
    let dlgHeader = 'Listenspalte bearbeiten';
    if (!rowData || rowData == null) {
      rowData = { id: 0, sortOrder: 0, validationListId: this.selectedList.id, colName: '', colDesc: '', colType: ColumnTypes.Text, isMandatory: true, createdAt: new Date, changedAt: new Date, validationRule: '', dbMapping: '', bubMapping: '',
        maxLength: 0, listOptions: 0, validRegEx: '', maxDigits: 0, maxDecimals: 0, isNumberOnly: 0
       };
      dlgHeader = 'Neue Spalte für Liste: ' + this.selectedList.listName;
    }
    const item: ValidationListColumn = lodash.clone(rowData);

    const ref = this.dialogService.open(EditValidationColumnDialogComponent,
      {
        header: dlgHeader,
        width: '64rem',
        data: { validationColumnToView: item }
      });
    ref.onClose.subscribe((validationColumn: ValidationListColumn) => {
      if (validationColumn) {
        if (validationColumn.id == 0) {
          this.store.dispatch(new AddValidationColumnAction({ validationColumn: item }));
        }
        else {
          this.store.dispatch(new SaveValidationColumnAction({ column: item }));
        }
      }
    });
  }

  deleteValidationColumn(rowData: ValidationListColumn) {
    this.confirmationService.confirm({
      message: 'Wollen Sie die Spalte "' + rowData.colName + '" wirklich löschen?',
      header: 'Verwendete Spalte löschen',
      acceptLabel: 'Löschen',
      rejectLabel: 'Abbrechen',
      accept: () => {
        this.store.dispatch(new DeleteValidationListColumnAction({ colId: rowData.id }));
      }
    });
  }

  editAvailableColumn(rowData: AvailableColumn) {
    let dlgHeader = 'Generelle Spalte bearbeiten';
    if (!rowData || rowData == null) {
      rowData = { id: 0, colName: '', colDesc: '', colType: ColumnTypes.Text, systemColumn: true, createdAt: new Date, changedAt: new Date, validationRule: '', dbMapping: '', bubMapping: '',
        maxLength: 0, listOptions: 0, validRegEx: '', maxDigits: 0, maxDecimals: 0, isNumberOnly: 0 };
      dlgHeader = 'Neue generelle Spalte';
    }
    const item: AvailableColumn = lodash.clone(rowData);

    const ref = this.dialogService.open(EditAvailableColumnDialogComponent,
      {
        header: dlgHeader,
        width: '100rem',
        data: { availableColumnToView: item }
      });
    ref.onClose.subscribe((availableColumn: AvailableColumn) => {
      if (availableColumn) {
        if (availableColumn.id == 0) {
          this.store.dispatch(new AddAvailableColumnAction({ availableColumn: availableColumn }));
        }
        else {
          this.store.dispatch(new SaveAvailableColumnAction({ column: availableColumn }));
        }
      }
    });
  }

  deleteAvailableColumn(rowData: AvailableColumn) {
    this.confirmationService.confirm({
      message: 'Wollen Sie die Spalte "' + rowData.colName + '" wirklich löschen?',
      header: 'Vorhandene Spalte löschen',
      acceptLabel: 'Löschen',
      rejectLabel: 'Abbrechen',
      accept: () => {
        this.store.dispatch(new DeleteAvailableColumnAction({ colId: rowData.id }));
      }
    });
  }

  editValidationList(rowData: ValidationList) {
    let dlgHeader = 'Liste bearbeiten';
    if (!rowData || rowData == null) {
      rowData = { id: 0, listName: "Neue Liste", isActive: true, listColums: [], createdAt: new Date, changedAt: new Date, isSupplier: false };
      dlgHeader = 'Neue Liste erstellen'
    }

    const item: ValidationList = lodash.clone(rowData);
    const ref = this.dialogService.open(EditValidationListDialogComponent,
      {
        header: dlgHeader,
        width: '37.5rem',
        data: { validationListToView: item }
      });
    ref.onClose.subscribe((list: ValidationList) => {
      if (list) {
        console.log(list);
        if (list.id == 0) {
          this.store.dispatch(new AddNewValidationListAction({ newValidationList: list }));
        }
        else {
          this.store.dispatch(new SaveValidationListAction({ validationList: item }));
        }
      }
    });
  }

  /**Warnung wenn Spalten nicht definiert, aber Aktiv Flag gesetzt */
  getValidationHint(rowData: ValidationList): Observable<string> {

    if (!rowData.isActive && !rowData.isSupplier) return of(null);
    let selectinfo = "Bitte selektieren Sie Spalten für die Liste";
    return this.validationListColumns$.pipe(map(v => {
      if (v == undefined) return null;
      if (v.length == 0) return null;
      let rowVals = v.filter(y => y && y.validationListId == rowData.id);
      if (rowVals.length == 0) return selectinfo;
      return null;
    }));

  }

  deleteValidationList(rowData: ValidationList) {
    this.confirmationService.confirm({
      message: 'Wollen Sie die Liste "' + rowData.listName + '" wirklich löschen?',
      header: 'Liste löschen',
      acceptLabel: 'Löschen',
      rejectLabel: 'Abbrechen',
      accept: () => {
        this.store.dispatch(new DeleteValidationListAction({ listId: rowData.id }));
        this.store.dispatch(new DeleteCustomConfigbyName({ name: rowData.listName, modul: CustomSettingsModulEnum.UploadEditor, type: CustomSettingsTypeEnum.Columns }));
      }
    });
  }

  reorderValidationCols(columnListTable) {
    let orderList: ColumnOrderModel[] = [];
    let i = 0;
    columnListTable.value.forEach(x => orderList.push({ columnId: x.id, orderNumber: i++ }));
    this.store.dispatch(new SaveValidationColumnsSortOrderAction({ columnOrder: orderList }));
  }

  showMessage(title: string, text: string) {
    this.confirmationService.confirm({
      header: title,
      message: text,
      acceptLabel: 'OK',
      rejectVisible: false
    });
  }

  getColType(columnType: ColumnTypes): string {
    switch (columnType) {
      case ColumnTypes.Text:
        return 'Text';
      case ColumnTypes.Number:
        return 'Zahl';
      case ColumnTypes.DynamicList:
        return 'Dynamische Liste';
      case ColumnTypes.List:
        return 'Liste';
      case ColumnTypes.CheckBox:
        return 'Ja/Nein';
      case ColumnTypes.DateRange:
        return 'Datumsbereich';
      case ColumnTypes.ExternalValidation:
        return 'Extern';
      case ColumnTypes.NumberRange:
        return 'Zahlenbereich';
      case ColumnTypes.Date:
        return 'Datum';
    }
  }

}
