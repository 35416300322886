import { AppState as RootState } from '../../store';
import { ValidationList, AvailableColumn, ValidationListColumn } from '@app/common/models';
import { createFeatureSelector } from '@ngrx/store';

export interface ValidationEditorState
{
    validationLists: ValidationList[];
    validationListColumns: ValidationListColumn[];
    availableColumns: AvailableColumn[];
}

export interface AppState extends RootState {
    validationEditorState: ValidationEditorState;
}

export const InitialValidationEditorState = {
    validationLists: [],
    validationListColumns: [],
    availableColumns: []
}

export const featureName = 'validationEditorState';

export const selectValidationEditorState = createFeatureSelector<ValidationEditorState>(featureName);